import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { graphqlOperation } from "@aws-amplify/api";
import { UserService } from '../users/user.service'

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  constructor(private amplifyService: AmplifyService, private userSevice: UserService) { }

  create = function (type, description, user_create, date_create, user_assigned, date_assigned, poiMaintenancesPoiId) {
    const createPoiMaintenances = `mutation($type: Int!, $description: String, $user_create: String!, $date_create: AWSDateTime!, $user_assigned: String!, $date_assigned: AWSDateTime!, $poiMaintenancesPoiId: ID) {
      createPoiMaintenances(input: {
            type: $type
            description: $description
            user_create: $user_create
            date_create: $date_create
            user_assigned: $user_assigned
            date_assigned: $date_assigned
            poiMaintenancesPoiId: $poiMaintenancesPoiId
           }) {
             id
           }
         }
       `;

    return this.amplifyService.api().graphql(graphqlOperation(createPoiMaintenances, {
      type: type,
      description: description,
      user_create: user_create,
      date_create: date_create,
      user_assigned: user_assigned,
      date_assigned: date_assigned,
      poiMaintenancesPoiId: poiMaintenancesPoiId
    }));
  }

  createAction = function (type, status, description, info, user, date, poiMaintenancesId) {
    const createPoiMaintenancesActions = `mutation($type: Int!, $status: Int!, $description: String, $info: AWSJSON, $user: String!, $date: AWSDateTime!, $poiMaintenancesActionsMaintenanceId: ID) {
      createPoiMaintenancesActions(input: {
            type: $type
            status: $status
            description: $description
            info: $info
            user: $user
            date: $date
            poiMaintenancesActionsMaintenanceId: $poiMaintenancesActionsMaintenanceId
           }) {
             id
           }
         }
       `;

    return this.amplifyService.api().graphql(graphqlOperation(createPoiMaintenancesActions, {
      type: type,
      status: status,
      description: description,
      info: JSON.stringify(info),
      user: user,
      date: date,
      poiMaintenancesActionsMaintenanceId: poiMaintenancesId
    }));
  }


}
