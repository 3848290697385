import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import Amplify, { Storage } from 'aws-amplify';
import { UserService } from '../users/user.service';
import { FilesService } from './files.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime, tap, switchMap, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.css']
})
export class FilesComponent implements OnInit {

  private user;
  private files = [];
  private stack = [];
  private status = "";
  private autocompleteForm: FormGroup;
  private autocompleteIsLoading = false;
  private autocompleteFiles = [];

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private fileService: FilesService,
  ) { }

  load(organization, folder, token) {
    let self = this;
    self.fileService.list(organization, folder, token)
      .then(data => {
        if (data && data.data && data.data.listFiless && data.data.listFiless.items) {
          self.files = self.files.concat(data.data.listFiless.items);
        }
        if (data && data.data && data.data.listFiless && data.data.listFiless.nextToken) {
          self.load(organization, folder, data.data.listFiless.nextToken);
        } else {
          self.status = "loaded";
        }
      })
      .catch(err => console.log(err));
  }

  loadFiles(organization) {
    let self = this, folder = "documents/" + self.user.organization + "/";
    if (self.stack.length > 0) {
      self.stack.forEach(stackElement => {
        folder += stackElement.name + "/";
      });
    }
    self.status = "loading";
    self.load(organization, folder, null);
  }

  ngOnInit() {
    let self = this;

    self.autocompleteForm = self.formBuilder.group({
      filesautocomplete: null
    })

    self.userService.currentuserAvailable.subscribe(data => {
      if (data) {
        self.user = self.userService.currentUser;
        self.loadFiles(self.user.organization);
      }
    });

    self.autocompleteForm
      .get('filesautocomplete')
      .valueChanges
      .pipe(
        debounceTime(300),
        tap(() => self.autocompleteIsLoading = true),
        switchMap(value => self.fileService.search(self.user.organization, value)
          .pipe(
            finalize(() => self.autocompleteIsLoading = false),
          )
        )
      )
      .subscribe(files => self.autocompleteFiles = (files && files.data && files.data.listFiless && files.data.listFiless.items) ? files.data.listFiless.items : []);
  }

  displayFn(data) {
    console.log(data);
    if (data) { return data.name; }
  }

  uploadFile() {
    let self = this;
    let dialogRef;

    dialogRef = self.dialog.open(AddFileDialogComponent, {
      data: {
        stack: self.stack
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        self.files = [];
        self.loadFiles(self.user.organization);
      }
    });
  }

  downloadFile(file) {
    if (file && file.s3path) {
      Storage.get(file.s3path, { level: 'public' })
        .then((result) => {
          if (result) {
            window.open(result.toString(), "_blank");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  deleteFile(file) {
    let self = this;
    if (file && file.id && file.s3path) {
      self.fileService.delete(file.id)
        .then((result) => {
          Storage.remove(file.s3path, { level: 'public' })
            .then((result) => {
              self.files = [];
              self.loadFiles(self.user.organization);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    console.log(file);
  }

  createFolder() {
    let self = this;
    let dialogRef;

    dialogRef = self.dialog.open(AddFolderDialogComponent, {
      data: {
        stack: self.stack
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        self.files = [];
        self.loadFiles(self.user.organization);
      }
    });
  }

  enterFolder(folder) {
    let self = this;
    self.stack.push(folder);
    self.files = [];
    self.loadFiles(self.user.organization);
  }

  navigateToFolder(folder) {
    let self = this, shadowstack = [];
    if (self.stack.length > 0) {
      for (let stackElement of self.stack) {
        if (stackElement != folder) {
          shadowstack.push(stackElement);
        }
        if (stackElement == folder) break;
      }
    }
    self.stack = shadowstack;
    self.files = [];
    self.loadFiles(self.user.organization);
  }
}

@Component({
  selector: 'app-files-addfile-dialog-component',
  templateUrl: './addfile.dialog.html',
})
export class AddFileDialogComponent implements OnInit {

  private user;
  private error: string;
  private stack = [];
  private status = 0;

  constructor(
    private userService: UserService,
    private fileService: FilesService,
    public dialogRef: MatDialogRef<AddFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[]) {
    this.stack = data["stack"];
  }

  ngOnInit() {
    let self = this;

    self.userService.currentuserAvailable.subscribe(data => {
      if (data) {
        self.user = self.userService.currentUser;
      }
    });
  }

  private upload(data) {
    let self = this, folder = "documents/" + self.user.organization + "/";
    if (self.stack.length > 0) {
      self.stack.forEach(stackElement => {
        folder += stackElement.name + "/";
      });
    }
    if (data && data.target && data.target.files) {
      self.status = 1;
      var filename = folder + data.target.files[0].name.toString();
      Storage.put(filename, data.target.files[0], {
        contentType: data.target.files[0].type,
        level: 'public',
        ACL: 'public-read'
      }).then(result => {
        if (result && result.hasOwnProperty("key") && result["key"]) {
          self.fileService.create(new Date(), self.user.organization, data.target.files[0].name.toString(), 'file', folder, result["key"])
            .then(data => {
              if (data && data.data && data.data.createFiles && data.data.createFiles.id) {
                this.dialogRef.close(true);
              }
            })
            .catch(err => console.log(err));
        }
      }).catch(err => console.log(err));
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    console.log("save");
  }

  close() {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-files-addfolder-dialog-component',
  templateUrl: './addfolder.dialog.html',
})
export class AddFolderDialogComponent implements OnInit {

  private user;
  private name;
  private error: string;
  private stack = [];

  constructor(
    private userService: UserService,
    private fileService: FilesService,
    public dialogRef: MatDialogRef<AddFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[]) {
    this.stack = data["stack"];
  }

  ngOnInit() {
    let self = this;

    self.userService.currentuserAvailable.subscribe(data => {
      if (data) {
        self.user = self.userService.currentUser;
      }
    });
  }

  private upload(foldername: string) {
    let self = this, folder = "documents/" + self.user.organization + "/";
    if (self.stack.length > 0) {
      self.stack.forEach(stackElement => {
        folder += stackElement.name + "/";
      });
    }
    self.fileService.create(new Date(), self.user.organization, foldername, 'folder', folder, folder + foldername)
      .then(data => {
        if (data && data.data && data.data.createFiles && data.data.createFiles.id) {
          this.dialogRef.close(true);
        }
      })
      .catch(err => console.log(err));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    let self = this;
    self.upload(self.name);
  }

  close() {
    this.dialogRef.close();
  }

}
