import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from '../users/user.service';
import { CitiesService } from '../cities/cities.service';
import { OrganizationsService } from '../organizations/organizations.service';
import { switchMap, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { City } from '../cities/city';
import { Organization } from "./organization";

@Component({
    selector: 'app-organizations',
    templateUrl: './organizations.component.html',
    styleUrls: ['./organizations.component.css']
})
export class OrganizationsComponent {

    private organizations = [];
    private user;

    constructor(private userService: UserService, private citiesService: CitiesService, private organizationsService: OrganizationsService, public dialog: MatDialog) {
        let self = this;

        self.userService.currentuserAvailable.subscribe(data => {
            if (data) {
                self.user = self.userService.currentUser;
                if (self.user && self.user["type"] && self.user["type"] == "SuperAdmins") {
                    self.organizationsService.listOrganizations().then(data => {
                        if (data && data.data && data.data.listOrganizationss && data.data.listOrganizationss.items) {
                            var allorganizations = data.data.listOrganizationss.items;
                            allorganizations.forEach(organization => {
                                if (organization) {
                                    self.organizations.push(organization);
                                }
                            });
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                }
            }
        });
    }

    addOrEditOrganization(organization) {
        let self = this;

        const dialogRef = self.dialog.open(OrganizationDialogComponent, {
            width: '350px',
            data: { new: (!organization) ? true : false }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result.new === undefined) {
                    self.organizationsService.create(result.name, null)
                        .then(data => {
                            self.organizations.push({ id: data.data.createOrganizations.id, name: result.name });
                        })
                        .catch(err => {
                            console.log(err);
                        });
                } else {
                    self.organizationsService.update(result.id, result.name, null)
                        .then(data => {
                            console.log(data);
                        })
                        .catch(err => {
                            console.log(err);
                        });
                }
            }
        });
    }
}

@Component({
    selector: 'app-organization-dialog-component',
    templateUrl: './organization.dialog.html',
    styleUrls: ['./organizations.component.css']
})
export class OrganizationDialogComponent implements OnInit {

    new: boolean;
    name: string;
    organizationForm: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<OrganizationDialogComponent>,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: Organization) {
        let self = this;
    }

    ngOnInit() {
        let self = this;
        self.organizationForm = self.fb.group({
            name: self.name
        })
    }

    onNoClick(): void {
        let self = this;
        self.dialogRef.close();
    }

    save() {
        let self = this;
        self.dialogRef.close({
            new: self.new,
            name: self.organizationForm.get('name').value,
            info: null
        });
    }

    close() {
        let self = this;
        self.dialogRef.close();
    }

}