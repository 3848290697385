import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../translate.service';
import { CitiesService } from '../cities/cities.service';
import { PoiService } from '../poi/poi.service';
import { UserService } from '../users/user.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as async from 'async';

@Component({
  selector: 'app-tabular',
  templateUrl: './tabular.component.html',
  styleUrls: ['./tabular.component.css']
})
export class TabularComponent implements OnInit {

  private user;
  private cities = [];
  private city = null;
  private poitypes = [];
  private layers = [];
  private pois = [];
  private datastructure = [];
  private datastructurefilter = [];
  private organization;

  private currentCity = null;
  private currentLayer = null;
  private currentPoitype = null;

  public datatable_columns_filtered = [];
  public datatable_columns = [];
  public datatable_rows = [];
  public loadingIndicator: boolean = false;

  private datatable_rows_selected = [];

  constructor(
    public dialog: MatDialog,
    private poiService: PoiService,
    private userService: UserService,
    private citiesService: CitiesService,
    private translate: TranslateService) {
    let self = this;
  }

  ngOnInit() {
    let self = this;

    self.userService.currentuserAvailable.subscribe(data => {
      if (data) {
        self.user = self.userService.currentUser;
        self.cities = self.user.activecities.sort((a, b) => a.name.toString().toLowerCase().localeCompare(b.name.toString().toLowerCase()));
        self.poitypes = [];
        Object.keys(self.user.organizationObject.poiTypes).forEach(key => {
          self.poiService.getType(self.user.organizationObject.poiTypes[key]).then(poiType => {
            self.poitypes.push(poiType.data.getPoiType);
          }).catch(err => {
            console.log(err);
          });
        });
      }
    });
  }

  getValue(column, value) {
    if (column.data) {
      if (column && column.data && (column.data.type == "radio" || column.data.type == "select")) {
        if (column.data.options) {
          var returnvalue = value;
          column.data.options.forEach(option => {
            if (option && option.value.toString() == value.toString()) returnvalue = option.label;
          });
          return returnvalue;
        }
        return value;
      }
      return value;
    } else return value;
  }


  loadRemote(id, organization, data?: boolean, poiNextToken?: string) {
    let self = this;
    self.citiesService.getAllFromCity(id, organization, data, poiNextToken)
      .then(data => {
        if (data && data.data && data.data.getCities && data.data.getCities.layers && data.data.getCities.layers.items) {
          var poilayers = data.data.getCities.layers.items;
          if (self.currentLayer) {
            poilayers.forEach(poilayercycle => {
              if (poilayercycle.id == self.currentLayer.id) {
                if (poilayercycle.poi && poilayercycle.poi.items) {
                  poilayercycle.poi.items.forEach(poi => {
                    if (poi.type && poi.type.name === self.currentPoitype.name) {
                      var poidata = JSON.parse(poi.data);
                      poidata["position_lat"] = poi.position_lat;
                      poidata["position_lng"] = poi.position_lng;
                      poidata["position_flr"] = poi.position_flr;
                      self.datatable_rows.push(poidata);
                    }
                  });
                  self.datatable_rows = [...self.datatable_rows];
                }
              }
            });
          }
        }
        if (data && data.data && data.data.getCities && data.data.getCities.layers && data.data.getCities.layers.items && data.data.getCities.layers.items.length) {
          data.data.getCities.layers.items.forEach(layer => {
            if (layer && layer.poi && layer.poi.nextToken) {
              self.loadRemote(id, organization, data, layer.poi.nextToken);
            }
          });
        }
        self.loadingIndicator = false;
      });
  }

  deleteRows() {
    let self = this;
    self.dialog.open(TabularDialog, {
      width: 'auto'
    }).afterClosed().subscribe(result => {
      if (result) {
        self.dialog.open(TabularDialog, {
          width: 'auto'
        }).afterClosed().subscribe(result => {
          if (result) {
            self.loadingIndicator = true;
            async.mapLimit(self.datatable_rows_selected, 5, (item, callback) => {
              if (item && item.poiID) {
                console.log(item.poiID);
                self.poiService.delete(item.poiID)
                  .then(data => {
                    callback();
                  })
                  .catch(err => {
                    console.log(err);
                    callback();
                  });
              } else callback();
            }, function (err, results) {
              self.loadingIndicator = false;
              self.datatable_rows = [];
              self.loadRemote(self.currentCity.id, self.user.organization, true, null);
            });
          }
        });
      }
    });
  }

  showData() {
    let self = this;
    self.datatable_rows = [];
    self.loadingIndicator = true;
    if (self.currentPoitype) {
      self.datatable_columns = [
        {
          headerName: "Poi",
          width: 50,
          filterParams: { newRowsAction: "keep" },
          checkboxSelection: function (params) {
            return params.columnApi.getRowGroupColumns().length === 0;
          },
          headerCheckboxSelection: function (params) {
            return params.columnApi.getRowGroupColumns().length === 0;
          }
        },
        { sortable: true, filter: true, headerName: "Latitude", field: 'position_lat', prop: 'position_lat', name: "Latitude", filterParams: { newRowsAction: "keep" } },
        { sortable: true, filter: true, headerName: "Longitude", field: 'position_lng', prop: 'position_lng', name: "Longitude", filterParams: { newRowsAction: "keep" } }
      ];
      if (self.currentPoitype.structure) {
        self.datastructure = JSON.parse(self.currentPoitype.structure);
        var structureGroups = Object.keys(self.datastructure);
        structureGroups.forEach(structureGroup => {
          if (self.datastructure[structureGroup] && self.datastructure[structureGroup].items) {
            var structureGroupElements = Object.keys(self.datastructure[structureGroup].items);
            structureGroupElements.forEach(structureGroupElement => {
              if (self.datastructure[structureGroup].items[structureGroupElement]) {
                var element = self.datastructure[structureGroup].items[structureGroupElement];
                var column = { sortable: true, filter: true, headerName: (self.translate.data[element.label] || element.label), field: structureGroupElement, prop: structureGroupElement, name: element.label, order: element.order, data: { type: element.type, options: (element.options) ? element.options : null }, filterParams: { newRowsAction: "keep" } };
                self.datatable_columns.push(column);
              }
            });
          }
        });
        self.datatable_columns_filtered = self.datatable_columns;
      }

      self.loadRemote(self.currentCity.id, self.user.organization, true, null);

    } else {
      self.datatable_columns = [
        { prop: 'position_lat', name: "Latitude" },
        { prop: 'position_lng', name: "Longitude" }
      ];
      self.loadingIndicator = false;
    }
  }

  onSelectedRows(event: any) {
    let self = this;
    if (event && event.api) self.datatable_rows_selected = event.api.getSelectedRows();
  }


  getRowClass(row) {
    return '';
  }

  isChecked(col) {
    let self = this;
    return self.datatable_columns_filtered.find(c => {
      return c.prop === col.prop;
    });
  }

  changedCity(e) {
    let self = this;
    self.currentCity = e.value;
    self.citiesService.getLayersFromCity(self.currentCity.id, self.user.organization)
      .then(data => {
        if (data && data.data && data.data.getCities && data.data.getCities.layers && data.data.getCities.layers.items && data.data.getCities.layers.items.length > 0) {
          self.layers = data.data.getCities.layers.items;
        }
      });
  }

  changePoiType(e) {
    let self = this;
    self.currentPoitype = e.value;
  }

  changedLayer(e) {
    let self = this;
    self.currentLayer = e.value;
  }

}

@Component({
  selector: 'tabular-dialog',
  templateUrl: './tabular.dialog.html',
})
export class TabularDialog {

  constructor(
    public dialogRef: MatDialogRef<TabularDialog>) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}