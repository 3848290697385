import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from './translate.service';

@Pipe({
  name: 'orderByOrderPipe'
})
export class orderByOrderPipe implements PipeTransform {

  transform(array: Array<string>, args: string): Array<string> {

    if (!array || array === undefined || array.length === 0) return null;

    array.sort((a: any, b: any) => {
      if (parseInt(a.order) < parseInt(b.order)) {
        return -1;
      } else if (parseInt(a.order) > parseInt(b.order)) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

}

@Pipe({
  name: 'orderByNamePipe'
})
export class orderByNamePipe implements PipeTransform {

  transform(array: Array<string>, args: string): Array<string> {

    if (!array || array === undefined || array.length === 0) return null;

    array.sort((a: any, b: any) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

}

@Pipe({
  name: 'orderByLabelPipe'
})
export class orderByLabelPipe implements PipeTransform {

  constructor(private translate: TranslateService) { }

  transform(array: Array<string>, args: string): Array<string> {

    if (!array || array === undefined || array.length === 0) return null;

    array.sort((a: any, b: any) => {
      if (this.translate.data[a.label] < this.translate.data[b.label]) {
        return -1;
      } else if (this.translate.data[a.label] > this.translate.data[b.label]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

}
@Pipe({
  name: 'orderByValuePipe'
})
export class orderByValuePipe implements PipeTransform {

  constructor(private translate: TranslateService) { }

  transform(array: Array<string>, args: string): Array<string> {

    if (!array || array === undefined || array.length === 0) return null;

    array.sort((a: any, b: any) => {
      if (this.translate.data[a.value] < this.translate.data[b.value]) {
        return -1;
      } else if (this.translate.data[a.value] > this.translate.data[b.value]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

}

@Pipe({
  name: "orderByPipe"
})
export class orderByPipe implements PipeTransform {
  transform(array: any, field: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      if (a[field] < b[field]) {
        return -1;
      } else if (a[field] > b[field]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}

@Pipe({
  name: 'filterUnique'
})
export class filterUnique implements PipeTransform {

  transform(value: any, args?: any): any {

    let uniqueArray = value.filter(function (el, index, array) {
      return array.indexOf(el) == index;
    });

    return uniqueArray;
  }
}