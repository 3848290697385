import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { graphqlOperation } from "@aws-amplify/api";
import { Organization } from '../organizations/organization';
import { UserService } from '../users/user.service'
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private amplifyService: AmplifyService, private userSevice: UserService) { }

  create = function (date, organizationID, name, type, parent, s3path) {
    const CreateFile = `mutation($date: AWSDateTime!, $organizationID: String!, $name: String, $type: String, $parent: String!, $s3path: String) {
        createFiles(input: {
            date: $date
            organizationID: $organizationID
            name: $name
            type: $type
            parent: $parent
            s3path: $s3path
           }) {
             id
           }
         }
       `;

    return this.amplifyService.api().graphql(graphqlOperation(CreateFile, {
      date: date,
      organizationID: organizationID,
      name: name,
      type: type,
      parent: parent,
      s3path: s3path
    }));
  }

  delete = function (id) {
    const DeleteFile = `mutation($id: ID!) {
      deleteFiles(input: {
            id: $id
           }) {
             id
           }
         }
       `;

    return this.amplifyService.api().graphql(graphqlOperation(DeleteFile, {
      id: id
    }));
  }

  list = function (organization: string, folder: string, poiNextToken?: string) {
    const listFiless = `
        query {
          listFiless(
            filter:{
              organizationID: {
                eq: "`+ organization + `"
              },
              parent: {
                eq: "`+ folder + `"
              }
            },
            `+ ((poiNextToken) ? (`nextToken: "` + poiNextToken + `"`) : ``) + `
          ) {
            items {
              id
              date
              name
              type
              s3path
            }
            nextToken
          }
        }
      `;

    return this.amplifyService.api().graphql(graphqlOperation(listFiless));
  }

  search = function (organization: string, name: string, poiNextToken?: string): Observable<any> {
    if (!name) return from(null);
    const searchFiless = `
        query {
          listFiless(
            filter:{
              organizationID: {
                eq: "`+ organization + `"
              },
              name: {
                contains: "`+ name + `"
              }
            },
            `+ ((poiNextToken) ? (`nextToken: "` + poiNextToken + `"`) : ``) + `
          ) {
            items {
              id
              date
              name
              type
              s3path
            }
            nextToken
          }
        }
      `;

    return from(this.amplifyService.api().graphql(graphqlOperation(searchFiless)));
  }

}
