import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable()
export class LocalStorageService {

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) { }

  public set(key: string, value: object): void {
    this.storage.set(key, value);
  }

  public get(key: string): void {
    return this.storage.get(key) || null;
  }
}