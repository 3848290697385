import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { graphqlOperation } from "@aws-amplify/api";
import Auth from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})
export class ImportsService {

  constructor(private amplifyService: AmplifyService) { }

}
