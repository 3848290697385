import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivitiesService } from '../activities.service';
import Amplify, { Storage } from 'aws-amplify';
import { PoiService } from '../poi/poi.service';
import { UserService } from '../users/user.service';
import { CitiesService } from '../cities/cities.service';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})
export class ActivitiesComponent {

  constructor(
    private activities: ActivitiesService) {
      let self = this;
      
      self.activities.getLogs(function(data){
        console.log(data);
      })
  }

}
