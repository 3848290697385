import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from '../users/user.service';
import { CitiesService } from '../cities/cities.service';
import { OrganizationsService } from '../organizations/organizations.service';
import { switchMap, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { City } from '../cities/city';
import { Organization } from "../organizations/organization";
import { ActivityType, ActivityOperation, ActivitiesService, ActivityDetail } from '../activities.service';

export interface User {
  loggeduser: object,
  new: boolean,
  email: string;
  password: string;
  activecities: string[];
  organization: string;
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent {

  private users = [];
  private user;

  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    private activities: ActivitiesService) {
    let self = this;

    self.userService.currentuserAvailable.subscribe(data => {
      if (data) {
        self.user = self.userService.currentUser;
        self.loadUsers();
      }
    });
  }

  checkIfUserCanEdit(user) {
    let self = this;
    if (self.user.type == 'SuperAdmins' || self.user.type == 'Admins') return true;
    return false;
  }

  activateUser(user) {
    let self = this;
    self.userService.activateUser(user)
      .then(data => {
        self.loadUsers();
        self.activities.log(ActivityType.ACTION, ActivityOperation.ACTIVITY, ActivityDetail.USERS_ACTIVATEUSER, user);
      })
      .catch(err => {
        console.log(err);
      });
  }

  disableUser(user) {
    let self = this;
    console.log(user);
    self.userService.disableUser(user)
      .then(data => {
        self.loadUsers();
        self.activities.log(ActivityType.ACTION, ActivityOperation.ACTIVITY, ActivityDetail.USERS_ACTIVATEUSER, user);
      })
      .catch(err => {
        console.log(err);
      });
  }

  enableUser(user) {
    let self = this;
    console.log(user);
    self.userService.enableUser(user)
      .then(data => {
        self.loadUsers();
        self.activities.log(ActivityType.ACTION, ActivityOperation.ACTIVITY, ActivityDetail.USERS_ACTIVATEUSER, user);
      })
      .catch(err => {
        console.log(err);
      });
  }

  loadUsers() {
    let self = this;
    if (self.user && self.user["type"])
      self.userService.listUsers((self.user["type"] == "SuperAdmins") ? null : self.user.organization).then(data => {
        if (data && data["Users"]) self.users = data["Users"];
      })
        .catch(err => {
          console.log(err);
        });
  }

  addOrEditUser(user) {
    let self = this;

    self.activities.log(ActivityType.ACTION, ActivityOperation.ACTIVITY, ActivityDetail.USERS_EDITUSER, (user) ? user.Username : "");
    const dialogRef = self.dialog.open(UserDialogComponent, {
      width: '350px',
      data: { loggeduser: self.user, new: (!user) ? true : false, email: (user) ? user.Username : "", password: "", activecities: (user) ? user.activecities : [] }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
        var organization = (typeof result.organization === 'string' || result.organization instanceof String) ? result.organization : result.organization.id;
        var activecities = [];
        result.activecities.forEach(city => {
          activecities.push(city.id);
        });
        if (result.new) {
          self.userService.signUp(result.email, result.password, activecities, organization)
            .then(data => {
              self.loadUsers();
              self.userService.setUserType(result.email, result.type).then(data => {
                self.activities.log(ActivityType.ACTION, ActivityOperation.ACTIVITY, ActivityDetail.USERS_CREATEDUSER, (user) ? user.Username : "");
              }).catch(err => {
                console.log(err);
              });
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          self.userService.updateUserActiveCities(result.email, activecities, organization)
            .then(data => {
              self.userService.setUserType(result.email, result.type).then(data => {
                console.log(data);
              }).catch(err => {
                console.log(err);
              });
            })
            .catch(err => {
              console.log(err);
            });
        }
      }
    });
  }
}

@Component({
  selector: 'app-user-dialog-component',
  templateUrl: './user.dialog.html',
  styleUrls: ['./users.component.css']
})
export class UserDialogComponent implements OnInit {

  new: boolean;
  email: string;
  password: string;
  activecities: string[];
  organization: string;
  type: string;

  private user;
  private organizations = [];

  private activeCitiesList: City[] = [];
  userForm: FormGroup;
  filteredCities: Observable<City[]>;

  constructor(
    private citiesService: CitiesService,
    private userService: UserService,
    private organizationsService: OrganizationsService,
    public dialogRef: MatDialogRef<UserDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: User) {
    let self = this;

    self.new = data.new;
    self.email = data.email;
    self.password = data.password;
    self.activecities = data.activecities;
    self.user = data.loggeduser;

    if (data && data.email) {
      self.userService.getUser(data.email).then(data => {
        if (data && data.hasOwnProperty("UserAttributes")) {
          data["UserAttributes"].forEach(attribute => {
            if (attribute.Name == "custom:activecities") {
              var cities = JSON.parse(attribute.Value);
              cities.forEach(city => {
                self.citiesService.get(city).then(data => {
                  if (data && data.data && data.data.getCities) self.activeCitiesList.push(data.data.getCities);
                })
                  .catch(err => {
                    console.log(err);
                  });
              });
            }
            if (attribute.Name == "custom:organization") {
              self.organization = attribute.Value;
              if (self.user && self.user["type"] && self.user["type"] == "SuperAdmins") {
                self.organizationsService.listOrganizations().then(data => {
                  if (data && data.data && data.data.listOrganizationss && data.data.listOrganizationss.items) {
                    var allorganizations = data.data.listOrganizationss.items;
                    allorganizations.forEach(organization => {
                      if (organization) {
                        self.organizations.push(organization);
                      }
                      if (organization.id == attribute.Value) {
                        self.organization = organization;
                        self.userForm.get('organization').setValue(organization);
                      }
                    });
                  }
                })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                self.organizationsService.get(self.user.organization).then(organization => {
                  self.organization = organization;
                })
                  .catch(err => {
                    console.log(err);
                  });
              }
            }
          });
        }
      })
        .catch(err => {
          console.log(err);
        });
      self.userService.getUserType(data.email).then(group => {
        if (group && group["Groups"] && group["Groups"].length > 0 && group["Groups"][0]) {
          self.userForm.get('type').setValue(group["Groups"][0].GroupName);
        }
      })
        .catch(err => {
          console.log(err);
        });
    } else {
      if (self.user && self.user["type"] && self.user["type"] == "SuperAdmins") {
        self.organizationsService.listOrganizations().then(data => {
          if (data && data.data && data.data.listOrganizationss && data.data.listOrganizationss.items) {
            var allorganizations = data.data.listOrganizationss.items;
            allorganizations.forEach(organization => {
              if (organization) {
                self.organizations.push(organization);
              }
            });
          }
        })
          .catch(err => {
            console.log(err);
          });
      } else {
        self.organizationsService.get(self.user.organization).then(data => {
          if (data && data.data && data.data.getOrganizations) self.organization = data.data.getOrganizations;
        })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  private onSelectedCity(event) {
    let self = this;
    if (event && event.option && event.option.value && self.activeCitiesList.map(city => city.id).indexOf((event.option.value as City).id) < 0) self.activeCitiesList.push(event.option.value);
  }

  private removeCity(city: City) {
    let self = this;
    if (city) {
      var index = self.activeCitiesList.map(city => city.id).indexOf((city as City).id);
      if (index >= 0) self.activeCitiesList.splice(index, 1);
    }
  }

  ngOnInit() {
    let self = this;
    self.userForm = self.fb.group({
      email: self.email,
      password: "",
      activeCities: null,
      organization: null,
      type: null,
    })
    self.filteredCities = self.userForm.get('activeCities').valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => self.citiesService.search(value))
      );
  }

  onNoClick(): void {
    let self = this;
    self.dialogRef.close();
  }

  save() {
    let self = this;
    self.dialogRef.close({
      new: self.new,
      email: self.userForm.get('email').value,
      password: self.userForm.get('password').value,
      activecities: self.activeCitiesList,
      organization: (self.user.type == 'SuperAdmins') ? self.userForm.get('organization').value : self.user.organization,
      type: self.userForm.get('type').value,
    });
  }

  close() {
    let self = this;
    self.dialogRef.close();
  }

  displayFn(city: City) {
    if (city) { return city.name; }
  }

  displayOrg(organization: Organization) {
    if (organization) { return organization.name; }
  }

}
