import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { graphqlOperation } from "@aws-amplify/api";
import Auth from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  constructor(private amplifyService: AmplifyService) { }

  get = function (id) {

    const getOrganization = `
        query {
          getOrganizations(id:"`+ id + `") {
            id
            name
            info
            poiTypes
          }
        }
      `;

    return this.amplifyService.api().graphql(graphqlOperation(getOrganization));
  }

  create = function (name, info) {
    const createOrganization = `
         mutation($name: String!, $info: AWSJSON) {
          createOrganizations(input: {
            name: $name
            info: $info
           }) {
            id
           }
         }
       `;

    if (info) {
      for (var k in info) {
        if (info.hasOwnProperty(k)) {
          if (!info[k] || typeof (info[k]) == 'undefined' || info[k] == null || info[k] === "")
            delete info[k];
        }
      }
    }

    return this.amplifyService.api().graphql(graphqlOperation(createOrganization, {
      name: name,
      info: JSON.stringify(info)
    }));
  }

  update = function (id, name, info) {
    const UpdateOrganization = `
         mutation($id: ID!, $name: String!, $info: AWSJSON) {
          updateOrganizations(input: {
            name: $name,
            info: $info
           }) {
             id
           }
         }
       `;

    if (info) {
      for (var k in info) {
        if (info.hasOwnProperty(k)) {
          if (!info[k] || typeof (info[k]) == 'undefined' || info[k] == null || info[k] === "")
            delete info[k];
        }
      }
    }

    return this.amplifyService.api().graphql(graphqlOperation(UpdateOrganization, {
      id: id,
      name: name,
      info: JSON.stringify(info),
    }));
  }

  listOrganizations = function () {
    const listOrganizations = `
        query {
          listOrganizationss {
            items {
              id
              name
            }
          }
        }
      `;

    return this.amplifyService.api().graphql(graphqlOperation(listOrganizations));
  }
}
