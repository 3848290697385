import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from '../users/user.service';
import { CitiesService } from '../cities/cities.service';
import { ExportsService } from '../exports/exports.service';
import { OrganizationsService } from '../organizations/organizations.service';
import { switchMap, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { City } from '../cities/city';
import { MatSnackBar } from '@angular/material';
import Amplify, { Storage } from 'aws-amplify';
import { PoiService } from '../poi/poi.service';
import { ActivityType, ActivityOperation, ActivitiesService, ActivityDetail } from '../activities.service';

export interface Export {
  organization: string,
  owner: string,
  date: Date;
  data: object;
}

@Component({
  selector: 'app-exports',
  templateUrl: './exports.component.html',
  styleUrls: ['./exports.component.css']
})
export class ExportsComponent {

  private user;
  private exports = [];

  constructor(
    private userService: UserService,
    private exportsService: ExportsService,
    public dialog: MatDialog,
    private activities: ActivitiesService) {

    let self = this;

    self.userService.currentuserAvailable.subscribe(data => {
      if (data) {
        self.user = self.userService.currentUser;
        self.exportsService.all(self.user.organization.toString()).then(exports => {
          if (exports && exports.data && exports.data.listExportss && exports.data.listExportss.items)
            var exports = exports.data.listExportss.items;
          exports.forEach(exportsrow => {
            self.exports.push({
              id: exportsrow.id,
              data: JSON.parse(exportsrow.data),
              date: exportsrow.date,
              owner: exportsrow.owner,
              fileCSV: exportsrow.fileuriCSV,
              fileXLSX: exportsrow.fileuriXLSX
            });
          });
        }).catch(err => {
          console.log(err);
        });
      }
    });
  }

  private deleteExport(exportToDelete: object) {
    let self = this;
    if (exportToDelete && exportToDelete["id"]) {
      self.exportsService.delete(exportToDelete["id"])
        .then(data => {
          if (data && data.data && data.data.deleteExports && data.data.deleteExports.id) {
            var index = 0;
            self.exports.forEach(function (currentexport: object) {
              if (currentexport["id"] == data.data.deleteExports.id) {
                self.exports.splice(index, 1);
                return true;
              }
              index++;
            });
          }
        }).catch(err => {
          console.log(err);
        });
    }
  }

  private download(file) {
    window.open(file, "_blank");
  }

  private copyUriToClipboard(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  private export() {
    let self = this;

    const dialogRef = self.dialog.open(ExportsDialogComponent, {
      width: '350px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        self.exportsService.create(result.organization, result.owner, result.data)
          .then(data => {
            console.log(data);
          }).catch(err => {
            console.log(err);
          });
      }
    });
  }

}


@Component({
  selector: 'app-export-dialog-component',
  templateUrl: './exports.dialog.html',
  styleUrls: ['./exports.component.css']
})
export class ExportsDialogComponent implements OnInit {

  new: boolean;
  email: string;
  password: string;
  activecities: string[];
  organization: string;
  type: string;
  emailtonotify: string;

  private user;
  private poitypes = [];
  private cities = [];
  private layers = [];
  private organizations = [];
  private currentPoitype;
  private currentCity;
  private currentLayer;

  private activeCitiesList: City[] = [];
  userForm: FormGroup;
  filteredCities: Observable<City[]>;

  constructor(
    private userService: UserService,
    private citiesService: CitiesService,
    private poiService: PoiService,
    private organizationsService: OrganizationsService,
    public dialogRef: MatDialogRef<ExportsDialogComponent>,
    private fb: FormBuilder) {
    let self = this;


    self.userService.currentuserAvailable.subscribe(data => {
      if (data) {
        self.user = self.userService.currentUser;
        self.cities = self.user.activecities.sort((a, b) => a.name.toString().toLowerCase().localeCompare(b.name.toString().toLowerCase()));
        Object.keys(self.user.organizationObject.poiTypes).forEach(key => {
          self.poiService.getType(self.user.organizationObject.poiTypes[key]).then(poiType => {
            self.poitypes.push(poiType.data.getPoiType);
          }).catch(err => {
            console.log(err);
          });
        });
      }
    });

  }

  private changePoitype(event) {
    let self = this;
    if (event && event.value) {
      self.currentPoitype = event.value;
    }
  }

  private changeCity(city) {
    let self = this;
    self.currentCity = (city.value) ? city.value : null;

    if (self.currentCity) {
      self.citiesService.getLayersFromCity(self.currentCity.id, self.user.organization)
        .then(data => {
          if (data && data.data && data.data.getCities && data.data.getCities.layers && data.data.getCities.layers.items)
            self.layers = data.data.getCities.layers.items;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private changeLayer(layer) {
    let self = this;
    self.currentLayer = (layer.value) ? layer.value : null;
  }


  ngOnInit() {

  }

  onNoClick(): void {
    let self = this;
    self.dialogRef.close();
  }

  save() {
    let self = this;
    self.dialogRef.close({
      organization: self.user.organization,
      owner: self.user.username,
      date: new Date(),
      data: {
        source: {
          city: self.currentCity,
          layer: self.currentLayer,
          poitype: self.currentPoitype
        },
        notification: {
          email: self.emailtonotify
        }
      }
    });
  }

  close() {
    let self = this;
    self.dialogRef.close();
  }


}
