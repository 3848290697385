import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import Amplify from 'aws-amplify';
import Analytics from '@aws-amplify/analytics';
import amplify from './aws-exports';

import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

Amplify.configure(amplify);

Analytics.configure();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
