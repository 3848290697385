import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { graphqlOperation } from "@aws-amplify/api";
import Auth from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})
export class ExportsService {

  constructor(private amplifyService: AmplifyService) { }

  delete = function (id) {
    const DeleteExport = `
         mutation($id: ID!) {
          deleteExports(input: {
            id: $id
           }) {
             id
           }
         }
       `;

    return this.amplifyService.api().graphql(graphqlOperation(DeleteExport, {
      id: id
    }));
  }

  create = function (organizationID, owner, data) {
    const CreateExport = `
         mutation($date: AWSDateTime!, $organizationID: String!, $owner: String, $data: AWSJSON) {
          createExports(input: {
            date: $date,
            organizationID: $organizationID,
            owner: $owner,
            data: $data
           }) {
             id
           }
         }
       `;

    if (data) {
      for (var k in data) {
        if (data.hasOwnProperty(k)) {
          if (!data[k] || typeof (data[k]) == 'undefined' || data[k] == null || data[k] === "")
            delete data[k];
        }
      }
    }

    return this.amplifyService.api().graphql(graphqlOperation(CreateExport, {
      date: new Date().toISOString(),
      organizationID: organizationID,
      owner: owner,
      data: JSON.stringify(data)
    }));
  }

  all = function (organization) {
    const listExportss = `
        query {
          listExportss(filter:{
            organizationID: {
              eq: "`+ organization + `"
            }
          }, limit: 100) {
            items {
              id
              date
              owner
              data
              fileuriCSV
              fileuriXLSX
            }
          }
        }
      `;

    return this.amplifyService.api().graphql(graphqlOperation(listExportss));
  }
}
