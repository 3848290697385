import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { map, switchMap, filter } from 'rxjs/operators';
import { Breakpoints, BreakpointState, BreakpointObserver } from '@angular/cdk/layout';
import { MapModule, MapAPILoader, MarkerTypeId, IMapOptions, IBox, ILatLong, IMarkerIconInfo, WindowRef, DocumentRef, MapServiceFactory, GoogleMapAPILoader, GoogleMapAPILoaderConfig } from 'angular-maps';
import { MatSidenav, MatSnackBar } from '@angular/material';
import { Poi } from '../poi/poi';
import { } from 'googlemaps';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LayerService } from '../layer/layer.service';
import { PoiService } from '../poi/poi.service';
import { UserService } from '../users/user.service';
import { CitiesService } from '../cities/cities.service';
import { LocationsService } from '../user/locations.service';
import { LoggerService } from '../logger.service';
import { ActivityType, ActivityOperation, ActivitiesService, ActivityDetail } from '../activities.service';
import Amplify, { Storage } from 'aws-amplify';
import { AmplifyService } from 'aws-amplify-angular';
import aws_exports from '../../aws-exports';
import { v4 as uuid } from 'uuid';
import { randomColor } from 'randomcolor';

Amplify.configure(aws_exports);

import { FormControl } from '@angular/forms';
import { Organization } from '../organizations/organization';
import { GeoLocation } from 'aws-sdk/clients/guardduty';
import { GeolocationService } from '../user/geolocation.service';
import { TranslateService } from '../translate.service';
import { LocalStorageService } from '../localstorage.service';

export interface PoiLayer {
  id: string,
  name: string;
  color: string;
}

@Component({
  selector: 'app-relieve',
  templateUrl: './relieve.component.html',
  styleUrls: ['./relieve.component.css']
})
export class RelieveComponent implements OnInit {

  @ViewChild('poiFormPanel') poiFormPanel: MatSidenav;
  @ViewChild('poiRightPanel') poiRightPanel: MatSidenav;
  private currentRightPanel = null;

  private filter_object;
  private filter_column;
  private filter_operator;
  private filter_value;
  private filter_group;
  private filtersGroups;
  private defaultPoiLayer = null;
  private searchpoi = false;
  private user;
  private cities = [];
  private currentCity = null;
  private pois = [];
  private numberingHistory = [1];
  private interdistanceHistory = [];
  private lastTwoPois = [];
  private poiOriginalData = null;
  private lastPoiSequentially;
  private poilayers = [];
  private poitypes = [];
  private poitypestructure = [];
  private selectedPoiLayers = [];
  private poiData = null;
  private poiDataUpdates = null;
  private poiFiles = [];
  private poiID = '';
  private poiForm = {};
  private poiFormPanelStatus = false;
  private currentPoiType = '';
  private lastInsertedPoi = null;
  private currentPoiLayer = null;
  private map;
  private bounds = null;
  private poiQrcode = null;
  private borderPolygon;
  private poiIconSize = 25;
  private layerLocked = true;
  private poitypeLocked = true;
  private poiAction = null;
  private poiInfowindowdata = null;
  private userGeolocationObj = null;
  private userPreferencies = [];
  private userPositions = [];
  private colleagues = [];
  private colleaguesKeys = Object.keys;
  private colleaguesIcons = [];
  private showColleagues = false;
  private colors = [];
  private customicons = [];
  private searchpoi_data = null;
  private currentMarker = {
    index: null,
    position: {
      latitude: 0,
      longitude: 0
    },
    marker: null,
    layer: null
  };
  private mapCenter = {
    latitude: 0,
    longitude: 0,
    zoom: 3
  }
  private mapOptions: IMapOptions = {
    disableBirdseye: true,
    disableStreetside: false,
    navigationBarMode: 1,
    zoom: 3,
    showCopyright: false
  };

  addRandomColors() {
    let self = this;
    for (var i = 0; i < 10; i++) {
      self.colors.push(randomColor({ format: 'rgb' }));
    }
  }

  loadPoisOnMap() {
    let self = this;
    if (self.poilayers) {
      for (let layer of self.poilayers) {
        layer.poi = {};
        layer.poi.items = [];
        self.loadAllFromLayerBounded(layer, self.user.organization, self.bounds.maxLatitude, self.bounds.maxLongitude, self.bounds.minLatitude, self.bounds.minLongitude);
      }
    }
  }

  updateMapBounds(e) {
    this.bounds = e;
  }

  clearPoisOnMap() {
    let self = this;
    if (self.poilayers) {
      for (let layer of self.poilayers) {
        layer.poi = {};
        layer.poi.items = [];
      }
    }
  }

  poiFormPanelEvent(e) { this.poiFormPanelStatus = e; }

  private mapReady(event) {
    let self = this;
    if (event) {
      event.then(data => {
        data.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('poi-map-toptoolbar-left'));
        data.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('poi-map-toptoolbar-right'));
        data.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(document.getElementById('poi-map-lefbottomtoolbar'));
        data.setOptions({
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER
          },
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.BOTTOM_LEFT
          },
          streetViewControl: true,
          streetViewControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER
          },
          fullscreenControl: false
        });
        self.map = data.data.map;
        self.map.setOptions({ tilt: 0 });
      });
    }
  }

  private poiDragEnd(data) {
    let self = this;
    if (data && data.Location) {
      self.currentMarker.position.latitude = data.Location.latitude;
      self.currentMarker.position.longitude = data.Location.longitude;
      self.lastTwoPois[0] = {
        lat: data.Location.latitude,
        lng: data.Location.longitude
      };
      if (self.interdistanceHistory && self.interdistanceHistory.length > 0) {
        var interdistance = self.distance(self.interdistanceHistory[1].lat, self.interdistanceHistory[1].lng, data.Location.latitude, data.Location.longitude);
        self.poiDataUpdates = { post_interdistance: interdistance };
      }
    }
  }

  private toggleRightPanel(panel) {
    let self = this;
    self.currentRightPanel = panel;
    self.poiRightPanel.toggle();
  }

  private editLayers() {
    let self = this;
    self.toggleRightPanel("layers");
  }

  private resetCurrentMarker() {
    let self = this;
    self.poiFormPanel.close();
    self.poiFiles = [];
    self.poiID = null;
    if (self.isNumeric(self.currentMarker.layer) && self.isNumeric(self.currentMarker.index)) {
      if (self.currentMarker && self.isNumeric(self.currentMarker.layer) && self.isNumeric(self.currentMarker.index) && self.poilayers[self.currentMarker.layer] && self.poilayers[self.currentMarker.layer].poi && self.poilayers[self.currentMarker.layer].poi.items && self.poilayers[self.currentMarker.layer].poi.items[self.currentMarker.index] && self.poilayers[self.currentMarker.layer].poi.items[self.currentMarker.index].current)
        self.poilayers[self.currentMarker.layer].poi.items[self.currentMarker.index].current = false;
      self.currentMarker.index = null;
      self.currentMarker.marker = null;
      self.currentMarker.position.latitude = 0;
      self.currentMarker.position.longitude = 0;
    }
  }

  private poiIcon(poi) {
    let self = this, icon = "";

    self.poitypes.forEach(poitype => {
      if (poitype && poitype.hasOwnProperty("name") && poi.hasOwnProperty("type") && poi.type != null && poi.type.hasOwnProperty("name") && poitype.name == poi.type.name) {
        icon = poitype.icon;
      }
    });

    if (poi.current) {
      return 'assets/img/markers/current.png';
    } else {

      if (poi.searchedFor) {
        return 'assets/img/markers/current.png';
      }

      if (self.filtersGroups && self.filtersGroups.length > 0) {
        for (var filtergroup of self.filtersGroups) {
          if (filtergroup.active && poi.data) {
            if (typeof (poi.data) == "string" || poi.data instanceof String) poi.data = JSON.parse(poi.data);
            var valid = false;
            var color = (filtergroup.color) ? filtergroup.color : "blue";
            for (var filter of filtergroup.filters) {
              if (poi.data[filter.column.prop]) {
                switch (filter.operator) {
                  case 0:
                    if (poi.data[filter.column.prop].toString() == filter.value.toString()) valid = true;
                    else valid = false;
                    break;
                  case 1:
                    if (parseFloat(poi.data[filter.column.prop]) > parseFloat(filter.value)) valid = true;
                    else valid = false;
                    break;
                  case 2:
                    if (parseFloat(poi.data[filter.column.prop]) < parseFloat(filter.value)) valid = true;
                    else valid = false;
                    break;
                  case 4:
                    if (poi.data[filter.column.prop].toString() != filter.value.toString()) valid = true;
                    else valid = false;
                    break;
                  case 5:
                    if (poi.data[filter.column.prop].toString().includes(filter.value.toString())) valid = true;
                    else valid = false;
                    break;
                  case 6:
                    if (poi.data[filter.column.prop].toString().startsWith(filter.value.toString())) valid = true;
                    else valid = false;
                    break;
                  case 7:
                    if (poi.data[filter.column.prop].toString().endsWith(filter.value.toString())) valid = true;
                    else valid = false;
                    break;
                  case 8:
                    if (!poi.data[filter.column.prop].toString().includes(filter.value.toString())) valid = true;
                    else valid = false;
                    break;
                }
              }
            }
            if (valid) {
              if (filtergroup.hide) {
                return 'data:image/svg+xml;utf-8, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" width="' + self.poiIconSize + '" height="' + self.poiIconSize + '" viewBox="0, 0, ' + self.poiIconSize + ', ' + self.poiIconSize + '"></svg>';
              } else {
                if (filtergroup.customicon) {
                  return filtergroup.customicon.URI;
                }
                else return icon.replace(/\\"/g, '"').replace(/##SIZE##/g, self.poiIconSize.toString()).replace(/##SIZE2##/g, (self.poiIconSize / 2).toFixed(1).toString()).replace(/##SIZERADIUS##/g, ((self.poiIconSize / 2) - 3).toFixed(1).toString()).replace(/##COLOR##/g, color.toString()).slice(1, -1);
              }
            }
          }
        }
      }
    }
    return icon.replace(/\\"/g, '"').replace(/##SIZE##/g, self.poiIconSize.toString()).replace(/##SIZE2##/g, (self.poiIconSize / 2).toFixed(1).toString()).replace(/##SIZERADIUS##/g, ((self.poiIconSize / 2) - 3).toFixed(1).toString()).replace(/##COLOR##/g, "red").slice(1, -1);
  }

  private isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  private showPoiInfoBubble(poi) {
    let self = this, stringdata = "";
    if (poi && poi.hasOwnProperty("type") && poi.type != null && poi.type.hasOwnProperty("name")) {
      self.poitypes.forEach(poitype => {
        if (poitype && poitype.hasOwnProperty("name") && poi.hasOwnProperty("type") && poi.type.hasOwnProperty("name") && poitype.name == poi.type.name) {
          let infowindow = JSON.parse(poitype.infowindow);
          if (infowindow && infowindow.hasOwnProperty("elements")) {
            let infowindowdatas = infowindow.elements;
            infowindowdatas.forEach(infowindowdata => {
              if (infowindowdata && infowindowdata.hasOwnProperty("type") && infowindowdata.hasOwnProperty("value")) {
                switch (infowindowdata.type) {
                  case "text":
                    stringdata += "<p>" + infowindowdata.value + "</p>";
                    break;
                  case "element":
                    if (poi && poi.hasOwnProperty("data") && poi.data.hasOwnProperty(infowindowdata.value))
                      stringdata += "<p>" + (self.translateService.data[infowindowdata.value] || infowindowdata.value) + ": " + poi.data[infowindowdata.value] + "</p>";
                    break;
                }
              }
            });
          }
        }
      });
      return stringdata;
    }
  }

  private poiCheckDuplication(value) {
    let self = this;
    self.poilayers.forEach(poilayer => {
      poilayer.poi.items.forEach(poi => {
        if (poi && poi.data) {
          if (typeof (poi.data) == "string" || poi.data instanceof String) poi.data = JSON.parse(poi.data);
          if (poi.data && poi.data.post_number && poi.data.post_number.toString().toLowerCase() === value.toLowerCase()) {
            poi.searchedFor = true;
            self.snackbar.open("Make attention, poi ID already exists!", 'ok', { duration: 5000, panelClass: ['white-snackbar'] });
            const dialogRef = this.dialog.open(DialogPOIExists, {
              width: '250px'
            });
          } else {
            poi.searchedFor = false;
          }
        }
      });
    });
  }

  private poiEditPropChanged(e) {
    let self = this;
    if (e && e.hasOwnProperty("prop") && e.prop.hasOwnProperty("key")) {
      switch (e.prop.key) {
        case "post_number":
          if (e.hasOwnProperty("value")) {
            self.poiCheckDuplication(e.value.toString());
          }
          break;
      }
    }
  }

  private searchPoiChanged(e) {
    let self = this;
    self.poilayers.forEach(poilayer => {
      poilayer.poi.items.forEach(poi => {
        if (poi && poi.data) {
          if (typeof (poi.data) == "string" || poi.data instanceof String) poi.data = JSON.parse(poi.data);
          if (poi.data && poi.data.post_number && poi.data.post_number.toString().toLowerCase() === self.searchpoi_data.toLowerCase()) {
            poi.searchedFor = true;
            self.mapCenter.latitude = poi.position_lat;
            self.mapCenter.longitude = poi.position_lng;
            self.mapCenter.zoom = 14;
          } else {
            poi.searchedFor = false;
          }
        }
      });
    });
  }

  private poiEdit(marker) {
    let self = this;
    self.poiAction = "editing";
    if (!self.poiFormPanel.opened && marker && marker.Marker && marker.Marker.Metadata && marker.Marker.Metadata.id && self.isNumeric(marker.Marker.Metadata.index) && self.isNumeric(marker.Marker.Metadata.layer)) {
      self.resetCurrentMarker();
      self.poiFiles = [];
      self.poilayers[marker.Marker.Metadata.layer].poi.items[marker.Marker.Metadata.index].current = true;
      self.currentMarker.index = marker.Marker.Metadata.index;
      self.currentMarker.layer = marker.Marker.Metadata.layer;
      self.currentMarker.marker = marker.Marker;
      self.poiOriginalData = {
        position: {
          latitude: marker.Location.latitude,
          longitude: marker.Location.longitude
        }
      };
      self.poiFormPanel.open();
      self.poiService.get(marker.Marker.Metadata.id)
        .then(data => {
          try {
            if (data && data.data && data.data.getPoi) {
              var poiData = data.data.getPoi;
              self.poiQrcode = "https://app.egaia.net/Dashboard/" + self.currentCity.id + "/" + poiData.id;
              self.currentPoiLayer = (poiData.layer) ? poiData.layer.id : null;
              self.currentPoiType = (poiData.type) ? poiData.type.id : null;
              self.poiForm = JSON.parse(poiData.type.structure);
              self.poiID = marker.Marker.Metadata.id;
              self.currentMarker.position.latitude = marker.Location.latitude;
              self.currentMarker.position.longitude = marker.Location.longitude;
              poiData.data = JSON.parse(poiData.data);
              if (poiData.data) {
                self.poiData = poiData.data;
                self.poiFiles = poiData.files;
              } else {
                throw "Data of poi not present";
              }
            } else {
              throw "Data of poi not present";
            }
          } catch (e) {
            self.poiFormPanel.open();
            self.resetCurrentMarker();
            self.poiFiles = [];
          }
        })
        .catch(err => {
          self.logger.error(err);
        });
    }
  }

  private loadAllFromLayerBounded(layer: object, organization: string, maxLatitude: number, maxLongitude: number, minLatitude: number, minLongitude: number, nextToken?: string) {
    let self = this;
    if (layer && layer["id"]) {
      self.citiesService.getAllPoiFromCityBounded(layer["id"], organization, maxLatitude, maxLongitude, minLatitude, minLongitude, nextToken)
        .then(data => {
          if (data && data.data && data.data.getPoiLayer && data.data.getPoiLayer.poi && data.data.getPoiLayer.poi.items) {
            layer["poi"]["items"] = layer["poi"]["items"].concat(data.data.getPoiLayer.poi.items);
          }
          if (data && data.data && data.data.getPoiLayer && data.data.getPoiLayer.poi && data.data.getPoiLayer.poi.nextToken) {
            self.loadAllFromLayerBounded(layer, organization, maxLatitude, maxLongitude, minLatitude, minLongitude, data.data.getPoiLayer.poi.nextToken);
          }
        })
        .catch(err => {
          self.logger.error(err);
        });
    }
  }

  private loadAllFromLayer(layer: object, organization: string, nextToken?: string) {
    let self = this;
    if (layer && layer["id"]) {
      self.citiesService.getAllPoiFromCity(layer["id"], organization, nextToken)
        .then(data => {
          if (data && data.data && data.data.getPoiLayer && data.data.getPoiLayer.poi && data.data.getPoiLayer.poi.items) {
            layer["poi"]["items"] = layer["poi"]["items"].concat(data.data.getPoiLayer.poi.items);
          }
          if (data && data.data && data.data.getPoiLayer && data.data.getPoiLayer.poi && data.data.getPoiLayer.poi.nextToken) {
            self.loadAllFromLayer(layer, organization, data.data.getPoiLayer.poi.nextToken);
          }
        })
        .catch(err => {
          self.logger.error(err);
        });
    }
  }

  private loadRemote(id, organization, data?: boolean) {
    let self = this;
    self.citiesService.getLayersFromCity(id, organization)
      .then(data => {
        if (data && data.data && data.data.getCities && data.data.getCities.layers && data.data.getCities.layers.items && data.data.getCities.layers.items.length > 0) {
          self.poilayers = data.data.getCities.layers.items;
          for (let layer of data.data.getCities.layers.items) {
            layer.poi = {};
            layer.poi.items = [];
            //self.loadAllFromLayer(layer, organization);
          }
        }
      })
      .catch(err => {
        self.logger.error(err);
      });
  }

  private addFilterGroup() {
    let self = this;
    self.filtersGroups.push({ groupIndex: self.filtersGroups.length + 1, filters: [], color: null, hide: false, active: true, customicon: null });
    self.addRandomColors();
  }

  private addFilter() {
    let self = this;
    self.filtersGroups[(self.filter_group - 1)].filters.push({
      poitype: self.filter_object,
      column: self.filter_column,
      operator: self.filter_operator,
      value: self.filter_value,
      customicon: null
    });
  }

  private deleteFilter(filtersGroup, filter) {
    let self = this, index = 0;
    filtersGroup.filters.forEach(filteri => {
      if (filter == filteri) {
        filtersGroup.filters.splice(index, 1);
      }
      index++;
    });
  }

  private deleteFilterGroup(filtergroup) {
    let self = this;
    self.filtersGroups.splice((parseInt(filtergroup) - 1), 1);
  }

  private showhideFilterGroup(filtergroup) {
    let self = this;
    self.filtersGroups[(filtergroup - 1)].hide = !self.filtersGroups[(filtergroup - 1)].hide;
  }

  private loadCity() {
    let self = this, poiNextToken = null;
    try {
      if (self.currentCity && self.user) {
        self.mapCenter.latitude = self.currentCity.position_lat;
        self.mapCenter.longitude = self.currentCity.position_lng;
        self.mapCenter.zoom = 12;
        self.poilayers = [];

        self.loadRemote(self.currentCity.id, self.user.organization, true);

        var borders = self.currentCity.area.toString().replace(/['"]+/g, "").split(" ");
        var bordersCoords = [];

        borders.forEach(border => {
          var borderCord = border.toString().split(",");
          var latitude = parseFloat(borderCord[1]);
          var longitude = parseFloat(borderCord[0]);
          if (borderCord && borderCord.length > 2 && latitude && longitude) bordersCoords.push({ lat: latitude, lng: longitude });
        });
        if (self.borderPolygon) self.borderPolygon.setMap(null);
        self.borderPolygon = new google.maps.Polygon({
          paths: bordersCoords,
          strokeColor: '#3a539b',
          strokeOpacity: 1,
          strokeWeight: 4,
          fillColor: '#FFF',
          fillOpacity: 0
        });
        self.borderPolygon.setMap(self.map);
      }
      self.defaultPoiLayer = self.localStorageService.get("egaia_defaultlayer_on_" + self.currentCity.id);
    } catch (e) {
      console.log(e);
    }
  }

  changeCity(city) {
    let self = this;
    self.currentCity = (city.value) ? city.value : null;
    self.filter_object = null;
    self.filter_column = null;
    self.filter_operator = null;
    self.filter_value = null;
    self.filter_group = null;
    self.filtersGroups = [
      {
        groupIndex: 1, filters: [{
          column: {
            label: "Post owner", prop: "post_owner", type: "radio", value: "0", options: [
              {
                "label": "Municipal or public",
                "value": "0"
              },
              {
                "label": "EnelSole",
                "value": "1"
              },
              {
                "label": "Project",
                "value": "2"
              },
              {
                "label": "Other",
                "value": "999"
              }
            ]
          },
          operator: 0,
          poitype: { id: "aab85a6b-582a-4f2c-a865-84201db5e051", name: "lighting" },
          value: "0",
        }], color: 'rgb(56,103,214)', hide: false, active: true, customicon: null
      },
      {
        groupIndex: 2, filters: [{
          column: {
            label: "Post owner", prop: "post_owner", type: "radio", value: "0", options: [
              {
                "label": "Municipal or public",
                "value": "0"
              },
              {
                "label": "EnelSole",
                "value": "1"
              },
              {
                "label": "Project",
                "value": "2"
              },
              {
                "label": "Other",
                "value": "999"
              }
            ]
          },
          operator: 0,
          poitype: { id: "aab85a6b-582a-4f2c-a865-84201db5e051", name: "lighting" },
          value: "1",
        }], color: 'rgb(235,59,90)', hide: false, active: true, customicon: null
      },
      {
        groupIndex: 3, filters: [{
          column: {
            label: "Post owner", prop: "post_owner", type: "radio", value: "0", options: [
              {
                "label": "Municipal or public",
                "value": "0"
              },
              {
                "label": "EnelSole",
                "value": "1"
              },
              {
                "label": "Project",
                "value": "2"
              },
              {
                "label": "Other",
                "value": "999"
              }
            ]
          },
          operator: 0,
          poitype: { id: "aab85a6b-582a-4f2c-a865-84201db5e051", name: "lighting" },
          value: "999",
        }], color: 'rgb(254,211,48)', hide: false, active: true, customicon: null
      },
      {
        groupIndex: 4, filters: [{
          column: {
            label: "Post owner", prop: "post_owner", type: "radio", value: "0", options: [
              {
                "label": "Municipal or public",
                "value": "0"
              },
              {
                "label": "EnelSole",
                "value": "1"
              },
              {
                "label": "Project",
                "value": "2"
              },
              {
                "label": "Other",
                "value": "999"
              }
            ]
          },
          operator: 0,
          poitype: { id: "aab85a6b-582a-4f2c-a865-84201db5e051", name: "lighting" },
          value: "2",
        }], color: 'rgb(38,222,129)', hide: false, active: true, customicon: null
      },
      {
        groupIndex: 5, filters: [{
          column: {
            label: "Cabinet owner", prop: "cabinet_owner", type: "radio", value: "0", options: [
              {
                "label": "Municipal or public",
                "value": "0"
              },
              {
                "label": "EnelSole",
                "value": "1"
              },
              {
                "label": "Project",
                "value": "2"
              },
              {
                "label": "Other",
                "value": "999"
              }
            ]
          },
          operator: 0,
          poitype: { id: "60166f16-2016-458f-92a4-16a51015134a", name: "cabinet" },
          value: "0",
        }], color: 'rgb(56,103,214)', hide: false, active: true, customicon: null
      },
      {
        groupIndex: 6, filters: [{
          column: {
            label: "Cabinet owner", prop: "cabinet_owner", type: "radio", value: "0", options: [
              {
                "label": "Municipal or public",
                "value": "0"
              },
              {
                "label": "EnelSole",
                "value": "1"
              },
              {
                "label": "Project",
                "value": "2"
              },
              {
                "label": "Other",
                "value": "999"
              }
            ]
          },
          operator: 0,
          poitype: { id: "60166f16-2016-458f-92a4-16a51015134a", name: "cabinet" },
          value: "1",
        }], color: 'rgb(235,59,90)', hide: false, active: true, customicon: null
      },
      {
        groupIndex: 7, filters: [{
          column: {
            label: "Cabinet owner", prop: "cabinet_owner", type: "radio", value: "0", options: [
              {
                "label": "Municipal or public",
                "value": "0"
              },
              {
                "label": "EnelSole",
                "value": "1"
              },
              {
                "label": "Project",
                "value": "2"
              },
              {
                "label": "Other",
                "value": "999"
              }
            ]
          },
          operator: 0,
          poitype: { id: "60166f16-2016-458f-92a4-16a51015134a", name: "cabinet" },
          value: "999",
        }], color: 'rgb(254,211,48)', hide: false, active: true, customicon: null
      },
      {
        groupIndex: 8, filters: [{
          column: {
            label: "Cabinet owner", prop: "cabinet_owner", type: "radio", value: "0", options: [
              {
                "label": "Municipal or public",
                "value": "0"
              },
              {
                "label": "EnelSole",
                "value": "1"
              },
              {
                "label": "Project",
                "value": "2"
              },
              {
                "label": "Other",
                "value": "999"
              }
            ]
          },
          operator: 0,
          poitype: { id: "60166f16-2016-458f-92a4-16a51015134a", name: "cabinet" },
          value: "2",
        }], color: 'rgb(38,222,129)', hide: false, active: true, customicon: null
      }
    ];
    self.loadCity();
  }

  constructor(
    private httpService: HttpClient,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private poiService: PoiService,
    private layerService: LayerService,
    private userService: UserService,
    private citiesService: CitiesService,
    private amplifyService: AmplifyService,
    private logger: LoggerService,
    private locationsService: LocationsService,
    private geolocationService: GeolocationService,
    private activities: ActivitiesService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog) {

    let self = this;

    /*
        self.userService.getCurrentUser().then(user => {
          self.user = user;
          self.poiService.getMinePoiTypeId().then(organizationPoiTypesId => {
            Object.keys(organizationPoiTypesId).forEach(key => {
              self.poiService.getType(organizationPoiTypesId[key]).then(poiType => {
                self.poitypes.push(poiType.data.getPoiType);
              }).catch(err => {
                console.log(err);
              });
            });
            if (self.user && self.user.attributes && self.user.attributes["custom:preferencies"]) {
              let preferencies = JSON.parse(self.user.attributes["custom:preferencies"]);
              Object.keys(preferencies).forEach(key => {
                self.userPreferencies[key] = preferencies[key];
              });
            }
          }).catch(err => {
            console.log(err);
          });
    
          self.geolocationService.getLocation({
            enableHighAccuracy: false,
            maximumAge: 0
          }).subscribe(
            function (position) {
              if (position && position.coords) self.locationsService.create(self.user.username, self.user.organization, position.coords.latitude, position.coords.longitude, 0, {});
            },
            function (error) {
              console.log(error);
            }
          );
    
        }).catch(err => {
          console.log(err);
        });*/

    /*self.locationsService.subscribe(function (data) {
      if (data && data.value && data.value.data && data.value.data.onCreateLocations && data.value.data.onCreateLocations.id) {
        self.locationsService.get(data.value.data.onCreateLocations.id)
          .then(data => {
            if (data && data.data && data.data.getLocations) {
              if (data.data.getLocations.organizationID == self.user.organization) {
                if (self.colleagues[data.data.getLocations.user]) {
                  self.colleagues[data.data.getLocations.user].position_lat = data.data.getLocations.position_lat;
                  self.colleagues[data.data.getLocations.user].position_lng = data.data.getLocations.position_lng;
                } else {
                  self.colleagues[data.data.getLocations.user] = data.data.getLocations;
                  self.userService.getUser(data.data.getLocations.user).then(user => {
                    if (user && user["UserAttributes"] && user["UserAttributes"].length > 0) {
                      for (var attribute of user["UserAttributes"]) {
                        if (attribute.Name == "custom:avatar") {
                          self.colleagues[data.data.getLocations.user]["avatar"] = "https://s3-eu-west-1.amazonaws.com/egaiaassets/public/" + attribute.Value;
                          self.colleaguesIcons[data.data.getLocations.user] = {
                            markerType: MarkerTypeId.RoundedImageMarker,
                            url: "https://s3-eu-west-1.amazonaws.com/egaiaassets/public/" + attribute.Value,
                            size: { width: 50, height: 50 },
                            markerOffsetRatio: { x: 0.5, y: 0.5 }
                          };
                          return true;
                        }
                      }
                    }
                  });
                }
              }
            }
          }).catch(err => {
            console.log(err);
          });
      }
    });*/

    self.poiService.subscribe(function (data) {
      if (data && data.value && data.value.data && data.value.data.onCreatePoi && data.value.data.onCreatePoi.id) {
        if (self.lastInsertedPoi != data.value.data.onCreatePoi.id) {
          self.poiService.get(data.value.data.onCreatePoi.id)
            .then(data => {
              try {
                if (data && data.data && data.data.getPoi && data.data.getPoi.city && data.data.getPoi.city.id && data.data.getPoi.organizationID && data.data.getPoi.layer && data.data.getPoi.layer.id) {
                  if (self.currentCity.id == data.data.getPoi.city.id && self.user.organization == data.data.getPoi.organizationID) {
                    self.poilayers.forEach(poilayer => {
                      if (poilayer.id == data.data.getPoi.layer.id) {
                        poilayer.poi.items.push({
                          id: (data.data.getPoi.id) ? data.data.getPoi.id : null,
                          data: (data.data.getPoi.data) ? JSON.parse(data.data.getPoi.data) : null,
                          type: { name: data.data.getPoi.type.name },
                          position_flr: (data.data.getPoi.position_flr) ? data.data.getPoi.position_flr : 0,
                          position_lat: (data.data.getPoi.position_lat) ? data.data.getPoi.position_lat : 0,
                          position_lng: (data.data.getPoi.position_lng) ? data.data.getPoi.position_lng : 0
                        });
                        if (self.userPreferencies && self.userPreferencies["notifynewpoints"] && self.userPreferencies["notifynewpoints"] == 'y') self.snackbar.open(data.data.getPoi.owner + ' added new poi', 'ok', { duration: 2000, panelClass: ['white-snackbar'] });
                      }
                    });
                  }
                }
              } catch (e) {
                console.log(e);
              }
            }).catch(err => {
              console.log(err);
            });
        }
      }
    });
  }


  changePoiType(event) {
    let self = this;
    if (event && event.value) {
      self.poitypes.forEach(poitype => {
        if (poitype.id === event.value) {
          if (poitype.structure) {
            self.poiForm = JSON.parse(poitype.structure);
            self.currentPoiType = poitype.id;
          }
        }
      });
    }
  }

  ngOnInit() {
    let self = this;
    let city = self.route.snapshot.paramMap.get('city');
    let selectedCity = null;

    self.httpService.get('https://app.egaia.net/assets/customicons.json').subscribe(
      data => {
        self.customicons = data as string[];
      },
      (err: HttpErrorResponse) => {
        self.customicons = [
          {
            "ID": "001",
            "URI": "https://app.egaia.net/assets/customicons/001.png"
          },
          {
            "ID": "002",
            "URI": "https://app.egaia.net/assets/customicons/002.png"
          },
          {
            "ID": "003",
            "URI": "https://app.egaia.net/assets/customicons/003.png"
          },
          {
            "ID": "004",
            "URI": "https://app.egaia.net/assets/customicons/004.png"
          },
          {
            "ID": "005",
            "URI": "https://app.egaia.net/assets/customicons/005.png"
          },
          {
            "ID": "006",
            "URI": "https://app.egaia.net/assets/customicons/006.png"
          },
          {
            "ID": "007",
            "URI": "https://app.egaia.net/assets/customicons/007.png"
          },
          {
            "ID": "008",
            "URI": "https://app.egaia.net/assets/customicons/008.png"
          },
          {
            "ID": "009",
            "URI": "https://app.egaia.net/assets/customicons/009.png"
          },
          {
            "ID": "010",
            "URI": "https://app.egaia.net/assets/customicons/010.png"
          }
        ];
      }
    );

    self.userService.currentuserAvailable.subscribe(data => {
      if (data) {
        self.user = self.userService.currentUser;
        self.cities = self.user.activecities.sort((a, b) => a.name.toString().toLowerCase().localeCompare(b.name.toString().toLowerCase()));
        self.poitypes = [];
        Object.keys(self.user.organizationObject.poiTypes).forEach(key => {
          self.poiService.getType(self.user.organizationObject.poiTypes[key]).then(poiType => {
            self.poitypes.push(poiType.data.getPoiType);
          }).catch(err => {
            console.log(err);
          });
        });
        self.route.params.subscribe(params => {
          self.cities.forEach(city => {
            if (city.name == params.city || city.id == params.city) {
              self.currentCity = city;
              self.loadCity();
            }
          });
        });
      }
    });

    /*        self.route.params.subscribe(params => {
                if (params && params.city && params.city) {
                  self.userService.getCurrentUser().then(data => {
                    self.cities.forEach(city => {
                      if (city.name == params.city || city.id == params.city) {
                        self.currentCity = city;
                        self.loadCity();
                        if (params.poi) {
                          self.poiService.get(params.poi)
                            .then(data => {
                              try {
                                if (data && data.data && data.data.getPoi) {
                                  var poiData = data.data.getPoi;
                                  self.poiQrcode = "https://app.egaia.net/Dashboard/" + self.currentCity.id + "/" + poiData.id;
                                  self.currentPoiLayer = (poiData.layer) ? poiData.layer.id : null;
                                  self.currentPoiType = (poiData.type) ? poiData.type.id : null;
                                  self.poiForm = JSON.parse(poiData.type.structure);
                                  self.poiID = params.poi;
                                  self.currentMarker.position.latitude = poiData.position_lat;
                                  self.currentMarker.position.longitude = poiData.position_lng;
                                  poiData.data = JSON.parse(poiData.data);
                                  if (poiData.data) {
                                    self.poiData = poiData.data;
                                    self.activities.log(ActivityType.REQUEST, ActivityOperation.DATARENDER, ActivityDetail.MAP_POIEDIT, self.poiID + " " + self.currentCity.name);
                                    self.poiFiles = poiData.files;
                                    self.poiFormPanel.open();
                                  } else {
                                    throw "Data of poi not present";
                                  }
                                } else {
                                  throw "Data of poi not present";
                                }
                              } catch (e) {
                                self.poiFormPanel.open();
                                self.resetCurrentMarker();
                                self.poiFiles = [];
                              }
                            })
                            .catch(err => {
                              self.logger.error(err);
                            });
                        }
                      }
                    });
                  });
                }
              });
            });
          })
          .catch(err => {
            self.logger.error(err);
          });*/
  }

  private moveToCurrentPosition() {
    let self = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        self.mapCenter.latitude = position.coords.latitude;
        self.mapCenter.longitude = position.coords.longitude;
        self.mapCenter.zoom = 15;
      }, function (error) {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            console.log("User denied the request for Geolocation.");
            break;
          case error.POSITION_UNAVAILABLE:
            console.log("Location information is unavailable.");
            break;
          case error.TIMEOUT:
            console.log("The request to get user location timed out.");
            break;
          default:
            console.log("An unknown error occurred.");
            break;
        }
      });
    }
  }

  private defaultPoiLayerSelected(e) {
    let self = this;
    if (e && e.value) {
      self.localStorageService.set("egaia_defaultlayer_on_" + self.currentCity.id, e.value);
    }
  }

  private poiUndo() {
    let self = this;
    switch (self.poiAction) {
      case 'editing':
        self.poiFormPanel.close();
        self.poiFiles = [];
        if (self.poiID && self.poiOriginalData) {
          self.currentMarker.position.latitude = self.poiOriginalData.position.latitude;
          self.currentMarker.position.longitude = self.poiOriginalData.position.longitude;
          self.currentMarker.marker.Latitude = self.poiOriginalData.position.latitude;
          self.currentMarker.marker.Longitude = self.poiOriginalData.position.longitude;
          self.poilayers[self.currentMarker.layer].poi.items[self.currentMarker.index].position_lat = self.poiOriginalData.position.latitude;
          self.poilayers[self.currentMarker.layer].poi.items[self.currentMarker.index].position_lng = self.poiOriginalData.position.longitude;
          self.poiOriginalData = null;
        }
        self.poiID = null;
        if (!self.currentMarker.index && !self.currentMarker.layer && typeof self.poilayers[self.poilayers.length - 1].name === "undefined") {
          self.poilayers[self.poilayers.length - 1].poi.items = [];
          self.poilayers = self.poilayers.slice(0, self.poilayers.length - 1);
        }
        break;
      case 'adding':
        self.poiFormPanel.close();
        self.poiFiles = [];
        if (!self.currentMarker.index && !self.currentMarker.layer && typeof self.poilayers[self.poilayers.length - 1].name === "undefined") {
          self.poilayers[self.poilayers.length - 1].poi.items = [];
          self.poilayers = self.poilayers.slice(0, self.poilayers.length - 1);
        } else {
          if (self.poilayers[self.currentMarker.layer] && self.poilayers[self.currentMarker.layer].poi && self.poilayers[self.currentMarker.layer].poi.items) {
            self.poilayers[self.currentMarker.layer].poi.items = self.poilayers[self.currentMarker.layer].poi.items.slice(0, self.poilayers[self.currentMarker.layer].poi.items.length - 1);
          }
        }
        self.poiID = null;
        break;
    }
    self.resetCurrentMarker();
  }

  private poiDeleteFile(event) {
    let self = this;
    if (event) {
      var filename = event.toString().replace("https://s3-eu-west-1.amazonaws.com/egaiaassets/public/", '');
      Storage.remove(filename)
        .then(result => {
          var fileindex = self.poiFiles.indexOf(event);
          if (self.isNumeric(fileindex)) self.poiFiles.splice(fileindex, 1);
        })
        .catch(err => console.log(err));
    }
  }

  private poiUpload(data) {
    let self = this;
    if (data && data.target && data.target.files) {
      var filename = "data/" + self.user.organization + "/" + self.currentCity.id + "/" + uuid() + "." + data.target.files[0].name.split('.').pop();
      Storage.put(filename, data.target.files[0], {
        contentType: data.target.files[0].type,
        level: 'public',
        ACL: 'public-read'
      })
        .then(result => {
          if (result && result["key"]) {
            if (!self.poiFiles) self.poiFiles = [];
            self.poiFiles.push("https://s3-eu-west-1.amazonaws.com/egaiaassets/public/" + result["key"]);
          }
        })
        .catch(err => self.logger.error(err));
    }
  }

  private poiRecenter() {
    let self = this;
    if (navigator.geolocation && self.currentMarker && self.isNumeric(self.currentMarker.index) && self.isNumeric(self.currentMarker.layer)) {
      navigator.geolocation.getCurrentPosition(function (position) {
        self.currentMarker.position.latitude = position.coords.latitude;
        self.currentMarker.position.longitude = position.coords.longitude;
        self.poilayers[self.currentMarker.layer].poi.items[self.currentMarker.index].position_lat = position.coords.latitude;
        self.poilayers[self.currentMarker.layer].poi.items[self.currentMarker.index].position_lng = position.coords.longitude;
        self.mapCenter.latitude = position.coords.latitude;
        self.mapCenter.longitude = position.coords.longitude;
        self.mapCenter.zoom = 16;
      }, function (error) {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            console.log("User denied the request for Geolocation.");
            break;
          case error.POSITION_UNAVAILABLE:
            console.log("Location information is unavailable.");
            break;
          case error.TIMEOUT:
            console.log("The request to get user location timed out.");
            break;
          default:
            console.log("An unknown error occurred.");
            break;
        }
      });
    }
  }

  private poiResetFields() {
    let self = this;
    self.poiDataUpdates = {
      notes: " ",
      project_notes: " ",
      rotosymmetric_optical_installation_pictures: " ",
      collar_makeover_pictures: " ",
      galvanization_pictures: " ",
      gardeding_operation_pictures: " ",
      outreach_painting_pictures: " ",
      outreach_replacement_pictures: " ",
      post_status_pictures: " ",
      rust_pictures: " ",
      terminalboard_makeover_pictures: " ",
      pictures: " ",
      collar_makeover: 0,
      galvanization: 0,
      gardeding_operation: 0,
      intervention_images: " ",
      outreach_painting: 0,
      outreach_replacement: 0,
      post_painting: 0,
      post_replacement: 0,
      post_status: 0,
      rust: 0,
      terminalboard_makeover: 0,
    }
  }

  private changePoiTypeFilter(event) {
    let self = this;
    if (event && event.value && event.value.structure) {
      var structure = JSON.parse(event.value.structure);
      self.poitypestructure = [];
      Object.keys(structure).forEach(structuregroup => {
        Object.keys(structure[structuregroup]["items"]).forEach(item => {
          structure[structuregroup]["items"][item].prop = item;
          self.poitypestructure.push(structure[structuregroup]["items"][item]);
        });
      });
    }
  }

  private poiAdd(position) {
    let self = this;
    if (!self.currentPoiLayer && self.defaultPoiLayer) self.currentPoiLayer = self.defaultPoiLayer;
    self.resetCurrentMarker();
    self.poitypeLocked = false;
    self.poiAction = "adding";
    self.poiFiles = [];
    self.poiData = {};

    if (self.numberingHistory && self.numberingHistory.length > 1) {
      if (self.userPreferencies && self.userPreferencies["poinumbercalc"]) {
        if (self.userPreferencies["poinumbercalc"] == "i") self.poiData.post_number = (self.numberingHistory[1] + (self.numberingHistory[1] - self.numberingHistory[0]));
        else self.poiData.post_number = ((self.numberingHistory[1] + (self.numberingHistory[1] - self.numberingHistory[0])) > 0) ? self.numberingHistory[1] + 1 : self.numberingHistory[1] - 1;
      } else {
        self.poiData.post_number = ((self.numberingHistory[1] + (self.numberingHistory[1] - self.numberingHistory[0])) > 0) ? self.numberingHistory[1] + 1 : self.numberingHistory[1] - 1;
      }
    }

    if (self.poiData.post_number) {
      self.poiCheckDuplication(self.poiData.post_number.toString());
    }

    if (self.interdistanceHistory && self.interdistanceHistory.length > 1) self.poiData.post_interdistance = self.distance(self.interdistanceHistory[0].lat, self.interdistanceHistory[0].lng, self.interdistanceHistory[1].lat, self.interdistanceHistory[1].lng);

    if (navigator.geolocation) {
      switch (position) {
        case "myPosition":
          navigator.geolocation.getCurrentPosition(function (position) {
            if (self.lastTwoPois[0]) {
              self.lastTwoPois[1] = {
                lat: self.lastTwoPois[0].lat,
                lng: self.lastTwoPois[0].lng
              };
            }
            self.lastTwoPois[0] = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            self.currentMarker.position.latitude = self.lastTwoPois[0].lat;
            self.currentMarker.position.longitude = self.lastTwoPois[0].lng;
            self.mapCenter.latitude = self.lastTwoPois[0].lat;
            self.mapCenter.longitude = self.lastTwoPois[0].lng;
            self.mapCenter.zoom = 16;
            if (self.defaultPoiLayer) {
              for (var i = 0; i < self.poilayers.length; i++) {
                if (self.poilayers[i].id == self.defaultPoiLayer) {
                  if (!self.poilayers[i].poi) self.poilayers[i].poi = { items: [] };
                  self.poilayers[i].poi.items.push({
                    id: null,
                    position_flr: 0,
                    position_lat: self.currentMarker.position.latitude,
                    position_lng: self.currentMarker.position.longitude,
                    current: true,
                    data: null,
                    type: null
                  });
                  self.currentMarker.index = self.poilayers[i].poi.items.length - 1;
                  self.currentMarker.layer = i;
                }
              }
            } else {
              self.poilayers.push({ poi: { items: [] } });
              self.poilayers[(self.poilayers.length - 1)].poi.items.push({
                id: null,
                position_flr: 0,
                position_lat: self.currentMarker.position.latitude,
                position_lng: self.currentMarker.position.longitude,
                current: true
              });
              self.currentMarker.index = self.poilayers[(self.poilayers.length - 1)].poi.items.length - 1;
              self.currentMarker.layer = (self.poilayers.length - 1);
            }
            if (self.lastTwoPois[1]) {
              self.selectedPoiLayers.push(self.poilayers[(self.poilayers.length - 1)]);
            }
            self.poiFormPanel.open();
            self.poiResetFields()
          }, function (error) {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                console.log("User denied the request for Geolocation.");
                break;
              case error.POSITION_UNAVAILABLE:
                console.log("Location information is unavailable.");
                break;
              case error.TIMEOUT:
                console.log("The request to get user location timed out.");
                break;
              default:
                console.log("An unknown error occurred.");
                break;
            }
          });
          break;
        case "mapCenter":
          if (self.lastTwoPois[0]) {
            self.lastTwoPois[1] = {
              lat: self.lastTwoPois[0].lat,
              lng: self.lastTwoPois[0].lng
            };
          }
          self.lastTwoPois[0] = {
            lat: self.map.center.lat(),
            lng: self.map.center.lng()
          };
          self.currentMarker.position.latitude = self.lastTwoPois[0].lat;
          self.currentMarker.position.longitude = self.lastTwoPois[0].lng;
          self.mapCenter.latitude = self.lastTwoPois[0].lat;
          self.mapCenter.longitude = self.lastTwoPois[0].lng;
          self.mapCenter.zoom = 16;

          if (self.defaultPoiLayer) {
            for (var i = 0; i < self.poilayers.length; i++) {
              if (self.poilayers[i].id == self.defaultPoiLayer) {
                if (!self.poilayers[i].poi) self.poilayers[i].poi = { items: [] };
                self.poilayers[i].poi.items.push({
                  id: null,
                  position_flr: 0,
                  position_lat: self.lastTwoPois[0].lat,
                  position_lng: self.lastTwoPois[0].lng,
                  current: true,
                  data: null,
                  type: null
                });
                self.currentMarker.index = self.poilayers[i].poi.items.length - 1;
                self.currentMarker.layer = i;
              }
            }
          } else {
            //self.poilayers.push({ poi: { items: [] } });
            self.poilayers[(self.poilayers.length - 1)].poi.items.push({
              id: null,
              position_flr: 0,
              position_lat: self.lastTwoPois[0].lat,
              position_lng: self.lastTwoPois[0].lng,
              current: true,
              data: null,
              type: null
            });
            self.currentMarker.index = self.poilayers[(self.poilayers.length - 1)].poi.items.length - 1;
            self.currentMarker.layer = (self.poilayers.length - 1);
          }
          if (self.lastTwoPois[1]) {
            self.selectedPoiLayers.push(self.poilayers[(self.poilayers.length - 1)]);
          }
          self.poiFormPanel.open();
          self.poiResetFields()
          break;
        case "sequentially":
          if (self.lastTwoPois.length > 1) {
            self.lastPoiSequentially = {
              lat: 2 * self.lastTwoPois[0].lat - self.lastTwoPois[1].lat,
              lng: 2 * self.lastTwoPois[0].lng - self.lastTwoPois[1].lng
            }
            self.lastTwoPois[1] = {
              lat: self.lastTwoPois[0].lat,
              lng: self.lastTwoPois[0].lng
            };
            self.lastTwoPois[0] = {
              lat: self.lastPoiSequentially.lat,
              lng: self.lastPoiSequentially.lng
            };
            self.currentMarker.position.latitude = self.lastTwoPois[0].lat;
            self.currentMarker.position.longitude = self.lastTwoPois[0].lng;
            self.mapCenter.latitude = self.lastTwoPois[0].lat;
            self.mapCenter.longitude = self.lastTwoPois[0].lng;
            self.mapCenter.zoom = 16;
            if (self.defaultPoiLayer) {
              for (var i = 0; i < self.poilayers.length; i++) {
                if (self.poilayers[i].id == self.defaultPoiLayer) {
                  if (!self.poilayers[i].poi) self.poilayers[i].poi = { items: [] };
                  self.poilayers[i].poi.items.push({
                    id: null,
                    position_flr: 0,
                    position_lat: self.lastTwoPois[0].lat,
                    position_lng: self.lastTwoPois[0].lng,
                    current: true,
                    data: null,
                    type: null
                  });
                  self.currentMarker.index = self.poilayers[i].poi.items.length - 1;
                  self.currentMarker.layer = i;
                }
              }
            } else {
              self.poilayers.push({ poi: { items: [] } });
              self.poilayers[(self.poilayers.length - 1)].poi.items.push({
                id: null,
                position_flr: 0,
                position_lat: self.lastTwoPois[0].lat,
                position_lng: self.lastTwoPois[0].lng,
                current: true,
                data: null,
                type: null
              });
              self.currentMarker.index = self.poilayers[(self.poilayers.length - 1)].poi.items.length - 1;
              self.currentMarker.layer = (self.poilayers.length - 1);
            }
            if (self.lastTwoPois[1]) {
              self.selectedPoiLayers.push(self.poilayers[(self.poilayers.length - 1)]);
            }
            self.poiFormPanel.open();
            self.poiResetFields()
          } else {
            self.snackbar.open('Insert almost two pois', 'ok', { duration: 2000, panelClass: ['white-snackbar'] });
          }
          break;
      }
    } else {
      self.currentMarker.position.latitude = self.currentCity.position_lat;
      self.currentMarker.position.longitude = self.currentCity.position_lng;
      self.mapCenter.latitude = self.currentCity.position_lat;
      self.mapCenter.longitude = self.currentCity.position_lng;
      self.mapCenter.zoom = 16;
      self.poilayers.push({ poi: { items: [] } });
      self.poilayers[(self.poilayers.length - 1)].poi.items.push({
        id: null,
        position_flr: 0,
        position_lat: self.currentCity.position_lat,
        position_lng: self.currentCity.position_lng,
        current: true,
        data: null,
        type: null
      });
      self.currentMarker.index = self.poilayers[(self.poilayers.length - 1)].poi.items.length - 1;
      self.currentMarker.layer = (self.poilayers.length - 1);
      if (self.lastTwoPois[1]) {
        self.selectedPoiLayers.push(self.poilayers[(self.poilayers.length - 1)]);
      }
      self.poiFormPanel.open();
      self.poiResetFields()
    }
  }

  private getPoiTypeFromId(id: String) {
    for (let poitype of this.poitypes) if (poitype.id.toString() == id.toString()) return poitype;
    return null;
  }

  private distance(lat1, lon1, lat2, lon2) {
    let self = this;
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var unit = "m";

      if (self.userPreferencies && self.userPreferencies["measureunit"]) {
        unit = self.userPreferencies["measureunit"];
      }

      var radlat1 = Math.PI * lat1 / 180;
      var radlat2 = Math.PI * lat2 / 180;
      var theta = lon1 - lon2;
      var radtheta = Math.PI * theta / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") { dist = dist * 1.609344 }
      if (unit == "m") { dist = dist * 1.609344 * 1000; }
      if (unit == "N") { dist = dist * 0.8684 }
      return Math.round(dist);
    }
  }

  private poiSave(data) {
    let self = this;
    if (self.currentCity && self.currentPoiLayer && self.currentPoiType) {
      if (data && data.post_number && Number.isInteger(parseInt(data.post_number))) {
        if (self.numberingHistory.length == 2) self.numberingHistory[0] = self.numberingHistory[1];
        else self.numberingHistory[0] = parseInt(data.post_number);
        self.numberingHistory[1] = parseInt(data.post_number);
      }
      if (self.currentMarker && self.currentMarker.position && self.currentMarker.position.latitude && self.currentMarker.position.longitude) {
        if (self.interdistanceHistory.length == 2) self.interdistanceHistory[0] = self.interdistanceHistory[1];
        else self.interdistanceHistory[0] = { lat: self.currentMarker.position.latitude, lng: self.currentMarker.position.longitude };
        self.interdistanceHistory[1] = { lat: self.currentMarker.position.latitude, lng: self.currentMarker.position.longitude };
      }
      var poitype = self.getPoiTypeFromId(self.currentPoiType);
      var icon = "-";
      var addnew = data.addnew;

      self.poilayers[self.currentMarker.layer].poi.items[self.currentMarker.index].data = data;
      self.poilayers[self.currentMarker.layer].poi.items[self.currentMarker.index].type = poitype;

      if (!data.poiID && data.poiID != "") {
        if (self.user) {
          self.poiService.create(self.currentCity.id, self.currentPoiLayer, self.currentPoiType, self.currentMarker.position.latitude, self.currentMarker.position.longitude, 0, data, self.user.username, self.user.organization, self.poiFiles, icon)
            .then(data => {
              if (data && data.data && data.data.createPoi && data.data.createPoi.id) {
                var insertedId = data.data.createPoi.id;
                self.lastInsertedPoi = insertedId;
              }
              self.resetCurrentMarker();
              if (addnew) {
                self.poiAdd("mapCenter");
              } else {
                self.poiFormPanel.close();
              }
              self.snackbar.open('Poi saved correctly', 'ok', { duration: 2000, panelClass: ['white-snackbar'] });
            })
            .catch(err => {
              self.logger.error(err);
              self.resetCurrentMarker();
            });
        }
      } else {
        if (self.user) {
          self.poiService.update(data.poiID, self.currentCity.id, self.currentPoiLayer, self.currentPoiType, self.currentMarker.position.latitude, self.currentMarker.position.longitude, 0, data, self.poiFiles, icon)
            .then(data => {
              self.resetCurrentMarker();
              if (addnew) {
                self.poiAdd("mapCenter");
              } else {
                self.poiFormPanel.close();
              }
              self.snackbar.open('Poi updated correctly', 'ok', { duration: 2000, panelClass: ['white-snackbar'] });
            })
            .catch(err => {
              self.logger.error(err);
              self.resetCurrentMarker();
            });
        }
      }
    } else {
      if (!self.currentCity) {
        self.snackbar.open('Select the city, before placing new poi', 'ok', { duration: 2000, panelClass: ['white-snackbar'] });
      }
      if (!self.currentPoiLayer) {
        self.snackbar.open('Select the layer, before placing new poi', 'ok', { duration: 2000, panelClass: ['white-snackbar'] });
      }
    }
  }

  private poiDelete(data) {
    let self = this;
    if (data)
      self.poiService.delete(data)
        .then(data => {
          self.poilayers[self.currentMarker.layer].poi.items.splice(self.currentMarker.index, 1);
          self.resetCurrentMarker();
          self.poiFormPanel.close();
          self.snackbar.open('Poi deleted correctly', 'ok', { duration: 2000, panelClass: ['white-snackbar'] });
        })
        .catch(err => {
          self.logger.error(err);
        });
  }

  private deletePoiLayer(id) {
    let self = this;
    if (id) {
      self.layerService.delete(id)
        .then(data => {
        })
        .catch(err => {
          self.logger.error(err);
        });
    }
  }

  private addOrEditPoiLayer(id) {
    let self = this;
    let dialogRef;

    if (id) {
      self.poilayers.forEach(poilayer => {
        if (poilayer.id == id) {
          dialogRef = self.dialog.open(PoiLayerDialogComponent, {
            width: '250px',
            data: { id: id, name: poilayer.name, color: "", layers: self.poilayers }
          });
        }
      });
    } else {
      dialogRef = self.dialog.open(PoiLayerDialogComponent, {
        width: '250px',
        data: { id: "", name: "", color: "", layers: self.poilayers }
      });
    }

    dialogRef.afterClosed().subscribe(result => {
      if (result && self.currentCity && self.currentCity.id) {
        if (self.user) {
          if (result.id) {
            self.layerService.update(result.id, result.name, "#FF0000", null)
              .then(data => {
                for (let poilayer of self.poilayers) {
                  if (poilayer.id === result.id) {
                    poilayer.name = result.name;
                    poilayer.color = result.color;
                    return;
                  }
                }
              })
              .catch(err => {
                self.logger.error(err);
              });
          } else {
            self.layerService.create(result.name, "#FF0000", null, self.currentCity.id, self.user.username, self.user.organization)
              .then(data => {
                self.poilayers.push({ id: data.data.createPoiLayer.id, name: result.name, color: '', data: '', poi: { items: [] } });
              })
              .catch(err => {
                self.logger.error(err);
              });
          }
        }
      }
    });
  }

}



@Component({
  selector: 'app-poilayer-dialog-component',
  templateUrl: './layer.dialog.html',
})
export class PoiLayerDialogComponent implements OnInit {

  id: string;
  name: string;
  color: string;
  layers: any[];

  error: string;

  constructor(
    public dialogRef: MatDialogRef<PoiLayerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PoiLayer) {
    this.id = data.id;
    this.name = data.name;
    this.color = data.color;
    this.layers = data["layers"];
  }

  ngOnInit() {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    let self = this;
    for (let poilayer of self.layers) {
      if (poilayer["name"].toString().toUpperCase() === self.name.toUpperCase()) {
        self.error = "Duplicate of layer is not allowed";
        return;
      }
    }
    self.dialogRef.close({
      id: self.id,
      name: self.name,
      color: self.color,
    });
  }

  close() {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-poilayer-dialog-component',
  template: '<h1 mat-dialog-title>Make attention, poi ID already exists</h1><div mat-dialog-content><div mat-dialog-actions><button mat-button (click)="onNoClick()">OK</button></div>',
})
export class DialogPOIExists {

  constructor(
    public dialogRef: MatDialogRef<DialogPOIExists>) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}