import { Component, OnInit, HostBinding } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UserService } from '../users/user.service';
import { TranslateService } from '../translate.service';
import Amplify, { Storage } from 'aws-amplify';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatSnackBar } from '@angular/material';
import { v4 as uuid } from 'uuid';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

    @HostBinding('class') componentCssClass;

    private user;
    private oldpassword = '';
    private newpassword = '';
    private newpasswordconfirm = '';
    private avatar;
    private userpreferencies = [];
    userPreferenciesForm: FormGroup;

    constructor(
        public overlayContainer: OverlayContainer,
        private fb: FormBuilder,
        private snackbar: MatSnackBar,
        private translateService: TranslateService,
        private userService: UserService) {
    }

    ngOnInit() {
        let self = this;
        self.userPreferenciesForm = self.fb.group({
            ui: (self.userpreferencies['ui']) ? new FormControl(self.userpreferencies['ui']) : null,
            lang: (self.userpreferencies['lang']) ? new FormControl(self.userpreferencies['lang']) : null,
            menustatus: (self.userpreferencies['menustatus']) ? new FormControl(self.userpreferencies['menustatus']) : null,
            measureunit: (self.userpreferencies['measureunit']) ? new FormControl(self.userpreferencies['measureunit']) : null,
            poinumbercalc: (self.userpreferencies['poinumbercalc']) ? new FormControl(self.userpreferencies['poinumbercalc']) : null,
            notifynewpoints: (self.userpreferencies['notifynewpoints']) ? new FormControl(self.userpreferencies['notifynewpoints']) : null,
        });

        self.userService.currentuserAvailable.subscribe(data => {
            if (data) {
                self.user = self.userService.currentUser;
                if (self.user && self.user.attributes && self.user.attributes["custom:preferencies"]) {
                    let preferencies = JSON.parse(self.user.attributes["custom:preferencies"]);
                    if (preferencies) {
                        Object.keys(preferencies).forEach(key => {
                            if (preferencies[key]) {
                                if (self.userPreferenciesForm.get(key)) self.userPreferenciesForm.get(key).setValue(preferencies[key].toString());
                                self.userpreferencies[key] = preferencies[key];
                            }
                        });
                    }
                }
                if (self.user && self.user.attributes && self.user.attributes["custom:avatar"]) {
                    self.avatar = "https://s3-eu-west-1.amazonaws.com/egaiaassets/public/" + self.user.attributes["custom:avatar"];
                }
            }
        });
    }

    private updatePassword() {
        let self = this;
        if (self.newpassword === self.newpasswordconfirm) {
            self.userService.changePassword(self.user, self.oldpassword, self.newpassword).then(data => {
                self.snackbar.open('Succesfully updated password', 'ok', { duration: 2000, panelClass: ['white-snackbar'] });
                self.oldpassword = '';
                self.newpassword = '';
                self.newpasswordconfirm = '';
            }).catch(err => {
                console.log(err);
            });
        } else {
            self.snackbar.open('Password check fails', 'ok', { duration: 2000, panelClass: ['white-snackbar'] });
        }
    }

    private savePreferencies() {
        let self = this;
        let preferencies = {
            "ui": (self.userPreferenciesForm.get('ui')) ? self.userPreferenciesForm.get('ui').value : 'light-theme',
            "lang": (self.userPreferenciesForm.get('lang')) ? self.userPreferenciesForm.get('lang').value : 'en',
            "menustatus": (self.userPreferenciesForm.get('menustatus')) ? self.userPreferenciesForm.get('menustatus').value : 'open',
            "measureunit": (self.userPreferenciesForm.get('measureunit')) ? self.userPreferenciesForm.get('measureunit').value : 'm',
            "poinumbercalc": (self.userPreferenciesForm.get('poinumbercalc')) ? self.userPreferenciesForm.get('poinumbercalc').value : 'i',
            "notifynewpoints": (self.userPreferenciesForm.get('notifynewpoints')) ? self.userPreferenciesForm.get('notifynewpoints').value : 'n'
        };
        self.userService.setUserPreferencies(self.user.username, preferencies).then(data => {
            console.log(data);
            self.snackbar.open('Succesfully updated preferencies', 'ok', { duration: 2000, panelClass: ['white-snackbar'] });
        }).catch(err => {
            console.log(err);
        });
    }

    private uploadAvatar(data) {
        let self = this;
        console.log(data);
        if (data && data.target && data.target.files) {
            var filename = "avatars/" + uuid() + "." + data.target.files[0].name.split('.').pop();
            Storage.put(filename, data.target.files[0], {
                contentType: data.target.files[0].type,
                level: 'public',
                ACL: 'public-read'
            })
                .then(result => {
                    if (result && result["key"]) {
                        self.userService.setUserAvatar(self.user.username, result["key"]).then(data => {
                            self.avatar = "https://s3-eu-west-1.amazonaws.com/egaiaassets/public/" + filename;
                            self.snackbar.open('Succesfully uploaded avatar', 'ok', { duration: 2000, panelClass: ['white-snackbar'] });
                        }).catch(err => {
                            console.log(err);
                        });
                    }
                })
                .catch(err => console.log(err));
        }
    }

    private isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    private onSetTheme(theme) {
        let self = this;
        if (theme && theme.value) {
            document.getElementById("appnavigator").className = theme.value;
            self.componentCssClass = theme.value;
        }
    }

    private onSetMenuStatus(status) {
        if (status && status.value) {
            console.log(status.value);
        }
    }

    private onSetlanguage(lang) {
        let self = this;
        if (lang && lang.value) {
            self.translateService.use(lang.value);
        }
    }
}
