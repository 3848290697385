import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { graphqlOperation } from "@aws-amplify/api";
import { UserService } from '../users/user.service'

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor(
    private amplifyService: AmplifyService,
    private userSevice: UserService) {

  }

  subscribe = function (cb) {
    const SubscribeToNewLocation = `subscription {
      onCreateLocations {
        id,
        position_lat,
        position_lng,
        position_flr,
        data,
        user,
        organizationID
      }
    }`;

    this.amplifyService.api().graphql(graphqlOperation(SubscribeToNewLocation)).subscribe(
      data => cb(data)
    );
  }

  get = function (id) {
    const getLocations = `query {
                            getLocations(id:"`+ id + `") {
                                id
                                date
                                organizationID
                                user
                                position_lat
                                position_lng
                                position_flr
                                data
                              }
                            }
                          `;

    return this.amplifyService.api().graphql(graphqlOperation(getLocations));
  }

  create = function (user, organizationID, lat, lng, flr, data) {
    const createLocations = `
         mutation($date: AWSDateTime!, $organizationID: String!, $user: String!, $position_lat: Float!, $position_lng: Float!, $position_flr: Float!, $data: AWSJSON, $duedate: Int!) {
          createLocations(input: {
            date: $date,
            organizationID: $organizationID,
            user: $user,
            position_lat: $position_lat,
            position_lng: $position_lng,
            position_flr: $position_flr,
            data: $data,
            duedate: $duedate
           }) {
             id
           }
         }
       `;

    if (data) {
      for (var k in data) {
        if (data.hasOwnProperty(k)) {
          if (!data[k] || typeof (data[k]) == 'undefined' || data[k] == null || data[k] === "")
            delete data[k];
        }
      }
    }

    return this.amplifyService.api().graphql(graphqlOperation(createLocations, {
      date: new Date().toISOString(),
      organizationID: organizationID,
      user: user,
      position_lat: lat,
      position_lng: lng,
      position_flr: flr,
      data: JSON.stringify(data),
      duedate: Math.round((new Date()).getTime() / 1000) + 600
    }));
  }

}
