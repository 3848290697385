import { Injectable } from '@angular/core';

import Amplify, { API } from 'aws-amplify';


export enum ActivityType {
  UI,
  ACTION,
  REQUEST,
  RESPONSE,
  EVENT
}
export enum ActivityOperation {
  RENDER,
  DATARENDER,
  READ,
  WRITE,
  ACTIVITY
}
export enum ActivityDetail {
  MAP_LAYERSPANEL_TOGGLE,
  MAP_POIEDIT,
  MAP_CHANGECITY,
  MAP_RENDERED,
  MAP_POIUNDO,
  MAP_ADDPOI,
  MAP_SAVEPOI,
  MAP_SAVEDPOI,
  MAP_UPDATEDPOI,
  MAP_DELETEDPOI,
  MAP_DELETEDLAYER,
  CITIES_RENDERED,
  CITIES_CHANGECITY,
  CITIES_ADDMARKER,
  CITIES_DELMARKER,
  MAP_SAVEDCITY,
  DYNAMICFORM_RENDERED,
  IMPORT_RENDERED,
  IMPORT_CHANGECITY,
  IMPORT_LOADFILE,
  IMPORT_UPLOADEDFILE,
  USERS_RENDERED,
  USERS_ACTIVATEUSER,
  USERS_EDITUSER,
  USERS_CREATEDUSER
}

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {

  constructor() { }

  public log(activityType: ActivityType, activityOperation: ActivityOperation, activityDetail: ActivityDetail, activityDescription: any) {
    let apiName = 'egaiaapigatewayapi';
    let path = '/activities';
    let param = {
      body: {
        msg: "[" + activityType + "][" + activityOperation + "][" + activityDetail + "]" + activityDescription
      },
      headers: {}
    }

    API.post(apiName, path, param).then(response => {
      //
    }).catch(error => {
      console.log(error)
    });
  }

  public getLogs(cb){
    let apiName = 'egaiaapigatewayapi';
    let path = '/activities?q=tre';
    let param = {
      body: {
        a: 2
      },
      headers: {}
    }

    API.get(apiName, path, param).then(response => {
      cb(response);
    }).catch(error => {
      console.log(error)
    });
  }

}
