import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivityType, ActivityOperation, ActivitiesService, ActivityDetail } from '../activities.service';
import { UserService } from '../users/user.service';
import { PoiService } from '../poi/poi.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})
export class DynamicFormComponent implements OnInit, OnChanges {

  @Input() formObject;
  @Input() dataObject;
  @Input() dataObjectUpdates;
  @Input() qrcode;
  @Input() idObject;
  @Input() files;
  @Input() action: string;
  @Input() mode: string;
  @Output() onSubmit: EventEmitter<Object> = new EventEmitter();
  @Output() onDelete: EventEmitter<Object> = new EventEmitter();
  @Output() onUpload: EventEmitter<Object> = new EventEmitter();
  @Output() onUndo: EventEmitter<Object> = new EventEmitter();
  @Output() onRecenter: EventEmitter<Object> = new EventEmitter();
  @Output() onDeleteFile: EventEmitter<Object> = new EventEmitter();
  @Output() onPropValueChanged: EventEmitter<Object> = new EventEmitter();

  private form: FormGroup;
  private sections;
  private objectProps;
  private objectData;
  private internalIdObject: String;
  private file;
  private currentFileselector = null;
  private user;
  private userPreferencies = [];

  constructor(
    private userService: UserService,
    private poiService: PoiService,
    private activities: ActivitiesService) {
    let self = this;

    self.form = new FormGroup({});

    /*
    self.userService.getCurrentUser().then(user => {
      self.user = user;
      if (self.user && self.user.attributes && self.user.attributes["custom:preferencies"]) {
        let preferencies = JSON.parse(self.user.attributes["custom:preferencies"]);
        Object.keys(preferencies).forEach(key => {
          self.userPreferencies[key] = preferencies[key];
        });
      }
    }).catch(err => {
      console.log(err);
    });
    self.activities.log(ActivityType.UI, ActivityOperation.RENDER, ActivityDetail.DYNAMICFORM_RENDERED, "");
    */
  }

  ngOnChanges(changes) {
    let self = this;
    if (changes.formObject && changes.formObject.currentValue) self.generateUIForm(changes.formObject.currentValue);
    if (changes.dataObject && changes.dataObject.currentValue) self.setFormData(changes.dataObject.currentValue);
    if (changes.dataObjectUpdates && changes.dataObjectUpdates.currentValue) self.setFormData(changes.dataObjectUpdates.currentValue);
    if (changes.idObject) self.internalIdObject = changes.idObject.currentValue;
    if (changes.qrcode) self.qrcode = changes.qrcode.currentValue;
    if (changes.files && changes.files.currentValue) self.files = changes.files.currentValue;
  }

  private fileselectorSelect(element) {
    this.currentFileselector = element;
  }

  private fileselectorSelected(event) {
    let self = this;
    if (self.currentFileselector && event && event.srcElement && event.srcElement.files && event.srcElement.files.length > 0 && event.srcElement.files[0].name) {
      self.form.controls[self.currentFileselector].setValue(self.form.controls[self.currentFileselector].value + "," + event.srcElement.files[0].name);
      self.currentFileselector = null;
    }
  }

  private setFormData(data) {
    let self = this;
    self.objectData = data;
    if (data && data && typeof data === 'object')
      Object.keys(data).map(key => {
        try {
          if (self.form.controls[key]) {
            self.form.controls[key].setValue(data[key]);
            if ((self.user && self.user.type == 'Viewers') || (self.mode != 'edit')) {
              self.form.controls[key].disable();
            }
          }
        } catch (e) {
          console.log(e);
        }
      });
  }

  private generateUIForm(data) {
    let self = this;
    var controls = {};
    self.sections = Object.keys(data)
      .map(propExt => {
        var itemExt = Object.assign({}, { key: propExt }, data[propExt]);
        itemExt.items = Object.keys(data[propExt].items)
          .map(propInt => {
            var itemInt = Object.assign({}, { key: propInt }, data[propExt].items[propInt]);
            itemInt.form_item_id = propInt;
            return itemInt;
          });
        return itemExt;
      });

    for (let section of Object.keys(self.sections)) {
      for (let prop of Object.keys(self.sections[section].items)) {
        controls[self.sections[section].items[prop].form_item_id] = new FormControl({ value: self.sections[section].items[prop].value || '', disabled: (self.mode != 'edit') ? true : false }, self.mapValidators(self.sections[section].items[prop].validation));
      }
    }

    this.form = new FormGroup(controls);
  }

  ngOnInit() {
  }

  private mapValidators(validators) {
    const formValidators = [];
    if (validators) {
      for (const validation of Object.keys(validators)) {
        if (validation === 'required') {
          formValidators.push(Validators.required);
        } else if (validation === 'min') {
          formValidators.push(Validators.min(validators[validation]));
        }
      }
    }
    return formValidators;
  }

  private onPropChanged(prop) {
    let self = this;
    self.onPropValueChanged.emit({ prop: prop, value: self.form.controls[prop.key].value });
  }

  private errorCheck(control_id: string, validator: string) {
    /*if (control_id && validator) {
      this.form.controls[control_id].setErrors({ notUnique: true });
    }*/
  }

  private submit(addnew, duplicate) {
    let self = this;
    var values = self.form.value;
    if (!duplicate) values.poiID = (self.internalIdObject) ? self.internalIdObject : null;
    else values.poiID = null;
    values.addnew = addnew;
    self.onSubmit.emit(values);
  }

  private deleteFile(file) {
    let self = this;
    self.onDeleteFile.emit((file) ? file : null);
  }

  private delete() {
    let self = this;
    self.onDelete.emit((self.internalIdObject) ? self.internalIdObject : null);
  }

  private upload(data) {
    let self = this;
    self.onUpload.emit(data);
  }

  private undo() {
    let self = this;
    self.onUndo.emit();
  }

  private recenter() {
    let self = this;
    self.onRecenter.emit();
  }

  private validateDependences(prop) {
    if (prop && prop.depends_on) {
      let self = this, depends_on = prop.depends_on;
      if (prop.hasOwnProperty("depends_on_operator")) {
        switch (prop.depends_on_operator) {
          case 'or':
            for (let depend_on of depends_on) {
              if (depend_on.value == self.form.controls[depend_on.item].value) {
                return true;
              }
            }
            self.form.controls[prop.key].setValue(prop.value);
            return false;
          case 'and':
            for (let depend_on of depends_on) {
              if (depend_on.value !== self.form.controls[depend_on.item].value) {
                self.form.controls[prop.key].setValue(prop.value);
                return false;
              }
            }
            return true;
        }
      } else {
        for (let depend_on of depends_on) {
          if (depend_on.value !== self.form.controls[depend_on.item].value) {
            self.form.controls[prop.key].setValue(prop.value);
            return false;
          }
        }
        return true;
      }
      return true;
    } else return true;
  }

}
