// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:9ee7564c-de98-4eb1-b729-656db0af6087",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_viF3jcufh",
    "aws_user_pools_web_client_id": "31isknuj7fh03a40uae0mdb21o",
    "aws_appsync_graphqlEndpoint": "https://65wqsseudbfpbaetonkvg5we5y.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "egaiaapigatewayapi",
            "endpoint": "https://tebng4an62.execute-api.eu-west-1.amazonaws.com/Prod",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "egaiaassets",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_content_delivery_bucket": "app.egaia.net",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d1qnp3b1odiged.cloudfront.net"
};


export default awsmobile;
