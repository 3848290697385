import { Component, OnInit } from '@angular/core';
import { UserService } from '../users/user.service';
import { MapModule, MapAPILoader, MarkerTypeId, IMapOptions, IBox, ILatLong, IMarkerIconInfo, WindowRef, DocumentRef, MapServiceFactory, GoogleMapAPILoader, GoogleMapAPILoaderConfig } from 'angular-maps';
import { } from 'googlemaps';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public user: Object;
  public cities: Object[];
  private borderPolygons;
  private map;
  private avatar: string;
  private citiesdone = false;

  private mapCenter = {
    latitude: "41.902782",
    longitude: "12.496366",
    zoom: 5
  }

  private mapOptions: IMapOptions = {
    disableBirdseye: true,
    disableStreetside: false,
    navigationBarMode: 1,
    zoom: 3,
    showCopyright: false
  };

  constructor(
    private userService: UserService) {
    let self = this;

    self.userService.currentuserAvailable.subscribe(data => {
      if (data) {
        self.user = self.userService.currentUser;
        console.log(self.user);
        self.cities = self.user["activecities"];
        if (self.user && self.user["attributes"] && self.user["attributes"]["custom:avatar"]) {
          self.avatar = "https://s3-eu-west-1.amazonaws.com/egaiaassets/public/" + self.user["attributes"]["custom:avatar"];
        }
      }
    });
  }

  ngOnInit() { }

  private mapReady(event) {
    let self = this;
    if (event) {
      event.then(data => {
        self.map = data.data.map;

        self.borderPolygons = [];
        self.cities.forEach((city, index) => {
          var borders = city["area"].toString().replace(/['"]+/g, "").split(" ");
          var bordersCoords = [];

          borders.forEach(border => {
            var borderCord = border.toString().split(",");
            var latitude = parseFloat(borderCord[1]);
            var longitude = parseFloat(borderCord[0]);
            if (borderCord && borderCord.length > 2 && latitude && longitude) bordersCoords.push({ lat: latitude, lng: longitude });
          });
          self.borderPolygons[index] = new google.maps.Polygon({
            paths: bordersCoords,
            strokeColor: '#fc5c65',
            strokeOpacity: 0.9,
            strokeWeight: 2,
            fillColor: '#fc5c65',
            fillOpacity: 0.25
          });
          self.borderPolygons[index].setMap(self.map);
        });
      });
    }
  }

}
