import { Injectable } from '@angular/core';
import { Logger } from 'aws-amplify';

import Amplify, { API } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {


  logger = new Logger('eGaiaLogger');

  constructor() { }

  log(message: any) {
    this.logRemote("log", message);
    this.logger.log(message);
  }
  info(message: any) {
    this.logRemote("info", message);
    this.logger.info(message);
  }
  warn(message: any) {
    this.logRemote("warn", message);
    this.logger.warn(message);
  }
  verbose(message: any) {
    this.logRemote("verbose", message);
    this.logger.verbose(message);
  }
  debug(message: any) {
    this.logRemote("debug", message);
    this.logger.debug(message);
  }
  error(message: any) {
    this.logRemote("error", message);
    this.logger.error(message);
  }

  logRemote(level, message) {
    let apiName = 'egaiaapigatewayapi';
    let path = '/log';
    let param = {
      body: {
        lvl: level,
        msg: message,
        src: "web-" + level
      },
      headers: {}
    }

    API.post(apiName, path, param).then(response => {
      console.log("ok");
    }).catch(error => {
      console.log(error)
    });
  }

}
